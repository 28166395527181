import React from "react";
import Slider from "react-styled-carousel";
import "./CollectionCard.css";
import { FaChevronCircleLeft, FaChevronCircleRight } from "react-icons/fa";
import BigCard from "./Bigcard";
import slideImages from "./SlideImagesJson";

const responsive = [
  { breakPoint: 1280, cardsToShow: 1 }, // this will be applied if screen size is greater than 1280px. cardsToShow will become 4.
  { breakPoint: 760, cardsToShow: 1 },
];

const BigCarousel = () => {
  return (
    <div className="bigcard-container">
      <Slider
        id="slider-container"
        hideArrowsOnNoSlides={true}
        showDots
        pauseOnMouseOver={true}
        autoSlide={5000}
        showArrows
        padding={"0px"}
        responsive={responsive}
        cardsToShow={1}
        LeftArrow={<FaChevronCircleLeft id="carousel-icons-left" />}
        RightArrow={<FaChevronCircleRight id="carousel-icons-right" />}
        infinite
      >
        {slideImages.map((item, index) => (
          <BigCard
            key={index}
            heading={item.heading}
            subheading={item.subheading}
            url={item.url}
          />
        ))}
      </Slider>
    </div>
  );
};

export default BigCarousel;
