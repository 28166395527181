import React, { useState } from "react";
import "./Navbar.css";
import { Link } from "react-router-dom";
import { BiSearch } from "react-icons/bi";
import { GiHamburgerMenu } from "react-icons/gi";
import { CSSTransition } from "react-transition-group";

const Navigationbar = () => {
  const [search, setSearch] = useState(false);
  const [menuClick, setMenuClick] = useState(false);
  const [searchVisible, setSearchVisible] = useState(false);
  const url = "https://incentive.sabrecwa.com/";
  const url1 = "https://www.sabre.com/what-we-do/our-businesses/";
  const url2 =
    "https://www.sabre.com/what-we-do/our-businesses/travel-solutions/";
  const url3 =
    "https://www.sabre.com/what-we-do/our-businesses/sabre-hospitality-solutions/";
  const url4 = "https://developer.sabre.com/home";
  const url5 = "https://www.sabre.com/what-we-do/our-technologies/";
  // const url6 = "https://www.sabre.com/what-we-do/our-businesses/travel-solutions/"
  const url7 = "https://www.youtube.com/user/SabreNewsTV";
  const url8 = "https://www.sabre.com/insights/labs/";
  const url9 =
    "https://www.sabre.com/about/joint-ventures-independent-distributors/";

  const handleClick = () => {
    setSearch(!search);
  };

  const handleClickMenu = () => {
    setMenuClick(!menuClick);
  };

  const handlesearchVisible = () => {
    setSearchVisible(!searchVisible);
  };

  const handleRewardClick = () => {
    window.open(url, "_blank", "noopener,noreferrer");
  };

  const handleBusClick = () => {
    window.open(url1, "_blank", "noopener,noreferrer");
  };

  const handleTravelClick = () => {
    window.open(url2, "_blank", "noopener,noreferrer");
  };

  const handleHospClick = () => {
    window.open(url3, "_blank", "noopener,noreferrer");
  };

  const handleDevStudClick = () => {
    window.open(url4, "_blank", "noopener,noreferrer");
  };

  const handleAppCentClick = () => {
    window.open(url5, "_blank", "noopener,noreferrer");
  };

  // const handleMatrClick = () =>{
  //     window.open(url6, '_blank', 'noopener,noreferrer');
  // }

  const handleVidClick = () => {
    window.open(url7, "_blank", "noopener,noreferrer");
  };

  const handleResLabClick = () => {
    window.open(url8, "_blank", "noopener,noreferrer");
  };

  const handleAllianceClick = () => {
    window.open(url9, "_blank", "noopener,noreferrer");
  };

  const collapseBar = () => {
    setMenuClick(false);
  };

  const handleTrademark = () => {
    window.open(
      "https://www.sabre.com/about/trademark/",
      "_blank",
      "noopener,noreferrer"
    );
  };

  const handleMbaClick = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };

  const handleBlogClick = () => {
    const blogSection = document.getElementById("blog-section");
    if (blogSection) {
      blogSection.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleNewsClick = () => {
    const newsSection = document.getElementById("news-section");
    if (newsSection) {
      newsSection.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <div className="cwa-mainbar-container">
      <div className="cwa-navbr-mn-container">
        <Link to="/" className="navbar-icon">
          <div id="imgIcon" />
        </Link>
        <div className="cwa-navbr-btn-container">
          <div className="cwa-navbr-btn-subcontainer">
            <li id="li" onClick={handleBusClick}>
              <Link id="alink">OUR BUSINESSES</Link>
              <div className="hover-business">
                <li onClick={handleTravelClick}>Travel Solutions</li>
                <li onClick={handleHospClick}>Hospitality Solutions</li>
              </div>
            </li>
            <li id="li">
              <Link id="alink">NEWS & INSIGHTS</Link>
              <div className="hover-business">
                <li onClick={handleBlogClick}>Blogs</li>
                <li onClick={handleNewsClick}>News</li>
              </div>
            </li>
            <li id="li">
              <Link id="alink">RESOURCES</Link>
              <div className="hover-business">
                <li onClick={handleResLabClick}>Sabre Labs & Research</li>
                <li onClick={handleDevStudClick}>Sabre Dev Studio</li>
                <li onClick={handleAppCentClick}>Red App Center</li>
                {/* <Link className="link-id" to="https://sabreng.com/pages/resources/Sabre_Manuals">
                  <li className="linked-id">Sabre Manuals</li>
                </Link> */}
                <li>
                  <a href="https://sabreng.com/pages/resources/Sabre_Manuals">
                    <ii id="alink">Sabre Manuals</ii>
                  </a>
                </li>
                <li onClick={handleVidClick}>Videos</li>
              </div>
            </li>
            <div id="li">
              <a href="https://www.sabre.com/about" id="li">
                <ii id="alink">ABOUT</ii>
              </a>
              <div className="hover-business">
                <li onClick={handleAllianceClick}>Alliances</li>
                <li>
                  <Link to="/contact-us" id="alink">
                    Contact Us
                  </Link>
                </li>
                <li>
                  <a href="https://sabreng.com/pages/executive_team" id="li">
                    <ii id="alink">Executive Team</ii>
                  </a>
                </li>
                <li onClick={handleTrademark}>Copyright and Trademark</li>
              </div>
            </div>
            {/* <Link to="/join-us" id="li"><li id='alink'>JOIN US</li></Link> */}
            <a href="https://sabreng.com/join" id="li">
              <ii id="alink">JOIN US</ii>
            </a>
            <Link onClick={handleRewardClick} id="li">
              REWARD
            </Link>

            <Link to="/join-us-babcock" id="li">
              <Link to="/join-us-babcock" id="alink">
                MBA PROGRAMME
              </Link>
              <div className="hover-business">
                <li>
                  <Link to="/join-us-babcock" id="alink">
                    Babcock MBA
                  </Link>
                </li>
                <li
                  onClick={() =>
                    handleMbaClick("https://gvu.edu.ng/mba-sabre/")
                  }
                >
                  Glorious Vision MBA
                </li>
              </div>
            </Link>
          </div>

          <div className="search-icon-contain" onClick={handleClick}>
            <BiSearch id="search-icon" />
          </div>

          <div className="search-icon-contain-min" onClick={handleClickMenu}>
            <GiHamburgerMenu id="hburg-icon" />
          </div>
        </div>
      </div>

      <CSSTransition
        in={search}
        timeout={350}
        className="cwa-navbr-container"
        unmountOnExit
      >
        <div className="search-conterner-box">
          <input
            className="searchinput"
            type="text"
            placeholder="Search For Keyword or Phrase"
          />
          <button id="searchbutn">SEARCH</button>
        </div>
      </CSSTransition>

      {menuClick ? (
        <div className="cwa-navbr-subcontainer" onClick={collapseBar}>
          <div className="navbar-min-title-container">
            <Link to="/" className="navbar-icon">
              <div className="img-logo" />
            </Link>
            <BiSearch id="search-icons" onClick={handlesearchVisible} />
          </div>

          {searchVisible ? (
            <div className="navbar-min-title-cont">
              <input
                className="searchinput"
                type="text"
                placeholder="Search For Keyword or Phrase"
              />
              <button id="searchbutn">SEARCH</button>
            </div>
          ) : (
            ""
          )}

          <div className="navbar-min-title-body">
            <div className="mini-containers">
              <div className="mini-containers-title" onClick={handleBusClick}>
                OUR BUSINESSES
              </div>
              <div className="mini-containers-bdy" onClick={handleTravelClick}>
                Travel Solutions
              </div>
              <div className="mini-containers-bdy" onClick={handleHospClick}>
                Hospitality Solutions
              </div>
            </div>
            <div className="mini-containers">
              <div
                className="mini-containers-title"
                onClick={handleRewardClick}
              >
                REWARDS
              </div>
              <div className="mini-containers-bdy" onClick={handleRewardClick}>
                Rewards
              </div>
            </div>
            <div className="mini-containers">
              <div className="mini-containers-title">
                <a href="https://www.sabre.com/about/" id="li">
                  <ii id="alink">ABOUT</ii>
                </a>
              </div>
              <div
                className="mini-containers-bdy"
                onClick={handleAllianceClick}
              >
                Alliances
              </div>
              <div className="mini-containers-bdy">
                <Link to="/contact-us" id="alinkk">
                  Contact Us
                </Link>
              </div>
              <div className="mini-containers-bdy">
                <a href="https://sabreng.com/pages/executive_team" id="li">
                  <ii id="alink">Executive Team</ii>
                </a>
              </div>
              <div className="mini-containers-bdy" onClick={handleTrademark}>
                Copyright and Trademark
              </div>
            </div>
            <div className="mini-containers">
              <div className="mini-containers-title">NEWS & EVENTS</div>
              <div className="mini-containers-bdy">Sabre Insights</div>
            </div>
            <div className="mini-containers">
              <div className="mini-containers-title">RESOURCES</div>
              <div className="mini-containers-bdy" onClick={handleResLabClick}>
                Sabre Labs & Research
              </div>
              <div className="mini-containers-bdy" onClick={handleDevStudClick}>
                Sabre Dev Studio
              </div>
              <div className="mini-containers-bdy" onClick={handleAppCentClick}>
                Red App Centre
              </div>
              <div className="mini-containers-bdy">Sabre Manuals</div>
              <div className="mini-containers-bdy" onClick={handleVidClick}>
                Sabre Videos
              </div>
            </div>
            <div className="mini-containers">
              <div className="mini-containers-title">
                <Link to="/join-us" id="alinkk">
                  JOIN US
                </Link>
              </div>
              <div className="mini-containers-bdy">
                <Link to="/join-us" id="alinkk">
                  Join Us
                </Link>
              </div>
            </div>
            <div className="mini-containers">
              <div className="mini-containers-title">MBA PROGRAMMES</div>
              <div className="mini-containers-bdy">
                <Link to="/join-us-babcock" id="alinkk">
                  Babcock MBA
                </Link>
              </div>
              <div
                className="mini-containers-bdy"
                onClick={() => handleMbaClick("https://gvu.edu.ng/mba-sabre/")}
              >
                Glorious Vision MBA
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default Navigationbar;
