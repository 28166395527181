import React, {useState} from 'react'
import { IoChevronDown } from "react-icons/io5";
import './Singleselect.css'

const Singleselect = ({selectedSocVal, check, itemList, placeholder}) => {
    const [socialvalue, setSocialvalue] = useState("")
    const [social, setSocial] = useState(false)
    const [socialjs] = useState(itemList)

    const handleSocialFocus = () =>{
        setSocial(!social)
    }
    
    const handleSocialClick = (e, incoming) =>{
        setSocialvalue(incoming.soc)
        setSocial(!social)
        selectedSocVal({val: incoming.soc, index:check})
    }

    const handleMouseLeave = () =>{
        setSocial(false)
    }
    
  return (
    <div className='single-select-container'>
        <input required value={socialvalue} onFocus={handleSocialFocus} placeholder={placeholder} id="bnw-firname-box"/>
            <IoChevronDown id="chevron-down" onClick={handleSocialFocus}/>
            {social && <div onMouseLeave={handleMouseLeave} className='bnw-region-drpdwn'>{socialjs.map((item, key) => (
                <span onClick={e => handleSocialClick(e, {soc: item.value})} key={key}>{item.value}</span>
            ))}
            </div>}
    </div>
  )
}

export default Singleselect