import { createReducer } from "@reduxjs/toolkit";

const initialState = {
  isLoading: true,
  newbloglist: [],
  datalist: [],
};

export const newsblogReducer = createReducer(initialState, {
  // get all orders of user
  getNewsBlog: (state) => {
    state.isLoading = true;
  },

  getNewsBlogSuccess: (state, action) => {
    state.isLoading = false;
    state.newbloglist = action.payload;
  },

  getNewsBlogFailed: (state, action) => {
    state.isLoading = false;
    state.error = action.payload;
  },
});
