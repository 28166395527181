import React from 'react'
import './Executiveteam.css'

const ExecteamContent = ({picturl, execname, exectitle, exepost, aboutexec}) => {
  return (
    <div className='execteamcontent-container'>
        <div className='execteamcontent-contop'>
            <div className='imagesize' style={{ backgroundImage: `url(${picturl})`}} />
            <div className='top-container-body'>
                <span>{execname}</span>
                <span>{exectitle}</span>
                <span>{exepost}</span>
            </div>
        </div>
        <div className='execteamcontent-conbotm'>
            <div id='persons-info' dangerouslySetInnerHTML={{__html: aboutexec}}/>
        </div>
    </div>
  )
}

export default ExecteamContent