import React from 'react'
import './contactus.css'

const Contactus = () => {
  return (
    <div className='flight-response-container'>
        <div className="container-fluid">
            <div className="row">
                <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3963.341361850382!2d3.3375809144497692!3d6.604433624050279!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x103b93cd55a1b743%3A0xcf38ecb90a7a938c!2sSabre%20Travel%20Solutions%2C%20Central%20%26%20West%20Africa!5e0!3m2!1sen!2sng!4v1680790223439!5m2!1sen!2sng"
                    width="600"
                    height="450"
                    style={{border:0}}
                    allowfullscreen=""
                    loading="lazy"
                    referrerpolicy="no-referrer-when-downgrade"
                    title="map"
                ></iframe>
            </div>
            <div className="row">
                <div className="col">
                    <h2 id='cnt-head2'>
                        Send Us A Message
                    </h2>
                    <p id='cnt-head1'>
                        Sabre West Africa serves travel agents, travel suppliers, online travel sites, corporations and governments throughout the world. As the world’s leading provider of travel solutions and the largest global distribution system, our reach is global — yet our service is personal and accessible.
                    </p>
                    <form className="row g-3">
                        <div className="col-12">
                            <input
                                type="text"
                                className="form-control"
                                id="name"
                                placeholder="Your Name"
                            />
                        </div>
                        <div className="col-md-6">
                            <input
                                type="email"
                                className="form-control"
                                id="emailAdd"
                                placeholder="Email Address"
                            />
                        </div>
                        <div className="col-md-6">
                            <input
                                type="text"
                                className="form-control"
                                id="phoneNo"
                                placeholder="Phone Number"
                            />
                        </div>
                        <div className="col-12">
                            <input
                                type="text"
                                className="form-control"
                                id="subject"
                                placeholder="Subject"
                            />
                        </div>
                        <div className="col-12">
                            <textarea className="form-control" placeholder="Write your message here..."></textarea>
                        </div>
                        <div className="col-12">
                            <div className="input g-recaptcha" data-sitekey="6LeT1goTAAAAAGTJTEN5RX3y1TeXBlgHu71xce6d" style={{width:'100%'}}></div>
                            <br/>
                        </div>
                        <div className="col-12">
                            <button type="submit" className="btn btn-primary">Send Message</button>
                        </div>
                    </form>
                </div>
                <div className="col" id='resp-col'>
                    <div className="row">
                        <div className="col-md-6">
                            <h2 style={{fontSize: '18px', color: '#333', marginTop:'15px'}}>
                                Sabre West Africa Headquaters
                            </h2>
                            <div style={{marginTop:'10px'}}>
                                <table width="100%" style={{fontSize:'13px', fontFamily: "'Univers LT W10_55 Roman', helvetica, arial, sans-serif", color: '#333333'}}>
                                    <tr>
                                        <td style={{width:'10%'}}>
                                            <i className="fa fa-map-marker" style={{color:'#e50000'}}></i>
                                        </td>
                                        <td style={{width:'90%'}}>
                                            PTL Towers, 3 Ajao Road, Off Adeniyi Jones,
                          P.O.Box 6613, Ikeja, Lagos State.
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{width:'10%'}}>
                                            <br/>
                                            <i className="fa fa-envelope" style={{color:'#e50000'}}></i>
                                        </td>
                                        <td style={{width:'90%'}}>
                                            <br/>
                                            enquiries@sabreng.com, helpdesk@sabreng.com
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{width:'10%'}}>
                                            <br/>
                                            <i className="fa fa-phone" style={{color:'#e50000'}}></i>
                                        </td>
                                        <td style={{width:'90%'}}>
                                            <br/>
                                            +234 807 929 7113, +234 812 801 4552
                                            <br/>
                                            <span style={{fontSize:'11px'}}>
                                                <b>TOLLFREE LINES:</b>
                                            </span>
                                            +234 8031230223, 8031230224
                                        </td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <h2 style={{fontSize: '18px', color: '#333', marginTop:'15px'}}>
                                Business Hours
                            </h2>
                            <div style={{marginTop:'10px', fontSize:'14px', fontFamily: "Univers LT W10_55 Roman, helvetica, arial, sans-serif", color: "#333333"}}>
                                <h5>
                                    <i className="fa fa-check" style={{color:'#e50000'}}></i>
                                    &nbsp;&nbsp; Monday - Friday
                                </h5>
                                <p style={{fontSize:'15px', fontFamily: 'Univers LT W10_55 Roman, helvetica, arial, sans-serif', color: '#333333', marginBottom:'10px'}}>8:00 AM - 5:30 PM</p>
                                <h5>
                                    <i className="fa fa-check" style={{color:'#e50000'}}></i>
                                    &nbsp;&nbsp;Saturday
                                </h5>
                                <p style={{fontSize:'15px', fontFamily: 'Univers LT W10_55 Roman, helvetica, arial, sans-serif', color: '#333333', marginBottom:'10px'}}>9:00 AM - 3:00 PM</p>
                                <h5>
                                    <i className="fa fa-times" style={{color:'#e50000'}}></i>
                                    &nbsp;&nbsp; Sunday
                                </h5>
                                <p style={{fontSize:'15px', fontFamily: 'Univers LT W10_55 Roman, helvetica, arial, sans-serif', color: '#333333'}}>Closed</p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <h2 style={{fontSize: '18px', color: '#333', marginTop:'15px'}}>
                            West Coast Contacts
                        </h2>
                        <hr style={{borderBottom: '1px dashed black'}}/>
                        <div className="col-md-6">
                            <p style={{fontSize:'13px', fontFamily: 'Univers LT W10_55 Roman, helvetica, arial, sans-serif', color: '#333333'}}>
                                <strong style={{fontSize:'13px'}}>
                                    ACCRA - GHANA
                                </strong>
                                <br/>
                                Tel: +233 268441455, 540518659.
                            </p>
                            <p style={{fontSize:'13px', fontFamily: 'Univers LT W10_55 Roman, helvetica, arial, sans-serif', color: '#333333', marginTop:'10px'}}>
                                <strong style={{fontSize:'13px'}}>
                                    DOUALA - CAMEROON
                                </strong>
                                <br/>
                                Tel: +237 73162477.
                            </p>
                            <p style={{fontSize:'13px', fontFamily: 'Univers LT W10_55 Roman, helvetica, arial, sans-serif', color: '#333333', marginTop:'10px'}}>
                                <strong style={{fontSize: '13px'}}>
                                    DAKAR - SENEGAL
                                </strong>
                                <br/>
                                Tel: +221 781558548.
                            </p>
                        </div>
                        <div className="col-md-6">
                            <p style={{fontSize: '13px', fontFamily: 'Univers LT W10_55 Roman, helvetica, arial, sans-serif', color: "#333333"}}>
                                <strong style={{fontSize:'13px'}}>
                                    CONAKRY - GUINEA
                                </strong>
                                <br/>
                                Tel: +224 666371285.
                            </p>
                            <p style={{fontSize:'13px', fontFamily: 'Univers LT W10_55 Roman, helvetica, arial, sans-serif', color: '#333333', marginTop:'10px'}}>
                                <strong style={{fontSize:'13px'}}>
                                    LIBERIA - MONROVIA
                                </strong>
                                <br/>
                                Tel: +231 886735558.
                            </p>
                            <p style={{fontSize:'13px', fontFamily: 'Univers LT W10_55 Roman, helvetica, arial, sans-serif', color: '#333333', marginTop:'10px'}}>
                                <strong style={{fontSize:'13px'}}>
                                    ABIDJAN - IVORY COAST
                                </strong>
                                <br/>
                                Tel: +255 682 24810.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row" style={{display:'flex', justifyContent: 'center', alignItem: 'center'}}>
                <div className="col-md-5">
                    <h2 style={{fontSize: '18px', lineHeight: '21px',color: '#333', marginTop:'10px'}}>Need Help?</h2>
                    <br/>
                    <p>If you have a critical issue, please contact our Support team at one of these phone numbers +234 807 929 7113, +234 812 801 4552, Tollfree Lines: +234 8031230223, 8031230224.</p>
                </div>
                <div className="col-md-4" style={{display:'flex', alignItems:'center', justifyContent:'flex-end'}}>
                    <img src="https://www.sabreng.com/sabreglobal/img/call-support.png" style={{width: '70px'}} alt=""/>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Contactus