import React, {useEffect} from 'react'
import {FaChevronDown, FaFacebookF, FaLinkedinIn, FaTwitter } from 'react-icons/fa'
import Blogcard from '../../Components/Cards/Blogcard/Blogcard'
import BlogFile from '../../Components/Cards/Blogcard/BlogJson'
import './blogpage.css'

const Blogpage = () => {
    useEffect(() => {
        // 👇️ scroll to top on page load
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    }, []);
  return (
    <div className='blogpagee-main-container'>
        <div className='blogpagee-container-top'>
            <div className='blogpagee-container-top-top'>
                <span>Article</span>
                <span>Connecting with industry and academic peers on innovations in travel retailing </span>
                <div id='underline'/>
                <span>September 8, 2022</span>
            </div>
            <div className='blog-main-img-container'/>
        </div>
        <div className='blogpagee-container-bottm'>
            <div className='left-div-blogpage'>
                <div className='left-blog-category'>
                    <div className='left-blog-subcategory'>
                        <span>Newsroom</span>
                        <FaChevronDown id='chevronicon'/>
                    </div>

                    <div className='left-blog-subcategory'>
                        <span>Press Releases</span>
                        <FaChevronDown id='chevronicon'/>
                    </div>

                    <div className='left-blog-subcategory'>
                        <span>Insight Blog</span>
                        <FaChevronDown id='chevronicon'/>
                    </div>

                    <div className='left-blog-subcategory'>
                        <span>Events</span>
                        <FaChevronDown id='chevronicon'/>
                    </div>
                </div>

                <div className='social-containers'>
                    <div className='social-subcontainers'>
                        <span>Share on Facebook</span>
                        <FaFacebookF id='socials-icons'/>
                    </div>

                    <div className='social-subcontainers'>
                        <span>Share on Twitter</span>
                        <FaTwitter id='socials-icons'/>
                    </div>

                    <div className='social-subcontainers'>
                        <span>Share on LinkedIn</span>
                        <FaLinkedinIn id='socials-icons'/>
                    </div>
                </div>
            </div>
            <div className='right-div-blogpage'>
                For many organizations and industries, the word ‘change’ often carries with it a negative emotional reaction: fear, anxiety, resistance. We understand that Low-Cost Carriers are often the exception that proves the rule – embracing change and turning it to their advantage. <br/><br/>

                LCCs accept and embrace the idea that change is inevitable – it’s a case of ‘when’ and not ‘if’. We’ve witnessed firsthand the way they’ve proven time and again their ability not only to adapt and evolve to the world around them, but to actively drive innovation and disruption within the airline industry. <br/><br/>

                This desire for progression and growth has driven LCCs to push the envelope and they’ve already set off on the journey to a retail-powered future of offers and orders. But we believe that even greater opportunities lie ahead. Opportunities to continue to think differently and build on existing capabilities. <br/><br/><br/>

                <b style={{fontSize: 25, fontWeight:700}}>What do those opportunities look like for LCCs?</b><br/><br/>
                In the future, we see huge possibilities for LCCs to more easily and economically extend indirect distribution, as well as broaden the types of ancillaries that are being retailed today. In addition, access to advanced data analytics such as AI/ML will enable LCCs to create and retail more personalized and contextualized offers, improving conversion and boosting revenue opportunities. <br/><br/>

                At Sabre, we’re committed to accelerating and leading industry change – in partnership with our customers – with a scalable yet modular solution. We’re enabling airlines to build the solutions that best meet their needs, at a pace that matches their transformation ambitions. <br/><br/>

                Here are <b>5 reasons why we believe Low-Cost Carriers are ideally placed for advanced retailing</b> that would see them grow market share and revenue: <br/><br/>

                For many organizations and industries, the word ‘change’ often carries with it a negative emotional reaction: fear, anxiety, resistance. We understand that Low-Cost Carriers are often the exception that proves the rule – embracing change and turning it to their advantage. <br/><br/>

                LCCs accept and embrace the idea that change is inevitable – it’s a case of ‘when’ and not ‘if’. We’ve witnessed firsthand the way they’ve proven time and again their ability not only to adapt and evolve to the world around them, but to actively drive innovation and disruption within the airline industry. <br/><br/>

                This desire for progression and growth has driven LCCs to push the envelope and they’ve already set off on the journey to a retail-powered future of offers and orders. But we believe that even greater opportunities lie ahead. Opportunities to continue to think differently and build on existing capabilities. <br/><br/><br/>

                <b style={{fontSize: 25, fontWeight:700}}>What do those opportunities look like for LCCs?</b><br/><br/>
                In the future, we see huge possibilities for LCCs to more easily and economically extend indirect distribution, as well as broaden the types of ancillaries that are being retailed today. In addition, access to advanced data analytics such as AI/ML will enable LCCs to create and retail more personalized and contextualized offers, improving conversion and boosting revenue opportunities. <br/><br/>

                At Sabre, we’re committed to accelerating and leading industry change – in partnership with our customers – with a scalable yet modular solution. We’re enabling airlines to build the solutions that best meet their needs, at a pace that matches their transformation ambitions. <br/><br/>

                Here are <b>5 reasons why we believe Low-Cost Carriers are ideally placed for advanced retailing</b> that would see them grow market share and revenue: <br/><br/>
            </div>
        </div>
        <div className='related-post-container'>
            <span id='related-post-id'>Related Post</span>
            <div className='related-post-subcontainer'>
                {BlogFile.map((item) => (
                    <Blogcard slideImageurl={item.url} dates={item.date} title={item.title} subtitle={item.subtl} linkurl={item.urlink}/>
                ))}
            </div>
        </div>
    </div>
  )
}

export default Blogpage