import React from 'react'
import '../cards.css'

const CardInnovation = () => {
  return (
    <div className='card-innovation-container'>
        <div className='col-innov-content-subinner'/>
        <div className='col-innov-img-subinner'/>
        <div className='col-innov-content-inner'>
            <div className='red-line-icon'/>
            <div className="container-sub-indust">
                <span className="indust-innov-top-text">Innovation</span>
                <span className="indust-innov-bottm-text">Sabre is investing in next-generation technology applications that will advance the intelligence behind our products and services, such as artificial intelligence, machine learning, and real-time data and analytics.</span>
            </div>
        </div>
    </div>
  )
}

export default CardInnovation