import React from "react";
import "./CollectionCard.css";
import "./Carouselcard.css";

const BigCard = ({ heading, subheading, url }) => {
  const handleClick = () => {};

  return (
    <div className="big-card-container">
      <div
        className="bigimage-container"
        style={{ backgroundImage: `url(${process.env.PUBLIC_URL + url})` }}
      >
        <div className="textfield-contains">
          <span id="textfield">{heading}</span>
          <span id="textfield1">{subheading}</span>
          {/* <button id='button' onClick={handleClick}>LEARN MORE ...</button> */}
          <button id="button">
            <a href="https://www.sabre.com/mosaic/">
              <ii className="text-[white]">LEARN MORE ...</ii>
            </a>
          </button>
        </div>
      </div>
    </div>
  );
};

export default BigCard;
