import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";
import Landing from "./Pages/Landingpage";
import Navigationbar from "./Components/Navbarr";
import Footers from "./Components/Footer/Footer";
import Blogpage from "./Pages/Blogpage/Blogpage";
// import Executiveteam from "./Pages/Executiveteam/Executiveteam";
// import Babcock from "./Pages/Babcock/Babcock";
import Resources from "./Pages/Resource/resources";
import Contactus from "./Pages/Contactus/contactus";
import Aboutus from "./Pages/Aboutus/Aboutus";
import Joinusnow from "./Pages/Joinus/Joinusnow";
import Babcockpage from "./Pages/Babcock/Babcock";
import Videos from "./Pages/Resource/videos";
import ViewMoreBlog from "./Pages/Blogpage/viewMoreBlog";
import ViewMoreNews from "./Components/Cards/Innovation/viewMoreNews";
import FullBlogPost from "./Pages/Blogpage/fullBlogPost";
// import FullNewsPost from "./Components/Cards/Innovation/fullNewsPost";
import { getNewsblogdata } from "./Redux/Actions/dataResponse";
import { Store } from "./Redux/store";
import FullNewsPost from "./Components/Cards/Innovation/fullNewsPost";
import Tickets from "./Pages/tickets";
import FullNuggetPost from "./Pages/Blogpage/fullNuggedPost";
import { ToastContainer } from "react-toastify";

const App = () => {
  useEffect(() => {
    Store.dispatch(getNewsblogdata());
  }, []);

  return (
    <Router>
      <Navigationbar />
      <div className="AppContainer">
        <Routes>
          <Route exact path="/" element={<Landing />} />
          <Route exact path="/aboutus" element={<Aboutus />} />
          <Route exact path="/contact-us" element={<Contactus />} />
          <Route exact path="/join-us" element={<Joinusnow />} />
          {/* <Route exact path="/join-us-babcock" element={<Babcockpage/>} /> */}
          <Route exact path="/our-blog" element={<Blogpage />} />
          <Route exact path="/insights/blog" element={<ViewMoreBlog />} />
          <Route exact path="/insights/news" element={<ViewMoreNews />} />

          <Route exact path="/tickets" element={<Tickets />} />

          <Route
            exact
            path="/news/insights/:newstitle"
            element={<FullNewsPost />}
          />

          <Route
            exact
            path="/blog/insights/:blogtitle"
            element={<FullBlogPost />}
          />

          <Route
            exact
            path="/nuggets/insights/:nuggetitle"
            element={<FullNuggetPost />}
          />

          {/* <Route exact path="/response/executiveteam" element={<Executiveteam />}/> */}
          <Route exact path="/join-us-babcock" element={<Babcockpage />} />
          <Route exact path="/response/resource" element={<Resources />} />
          <Route exact path="/response/videos" element={<Videos />} />
        </Routes>
      </div>
      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
      <Footers />
    </Router>
  );
};

export default App;
