import { createAction, createReducer } from "@reduxjs/toolkit";

const searchData = createAction("searchData");
const searchResourceData = createAction("searchResourceData");

const initialState = {
  datalist: [],
  resourcesdata: [],
};

export const dataReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(searchData, (state, action) => {
      state.datalist = action.payload;
    })

    .addCase(searchResourceData, (state, action) => {
      state.resourcesdata = action.payload;
    });
});
