import React, { useEffect, useState } from "react";
import Card from "../Components/Cards/Card";
import "./pages.css";
import Smallercards from "../Components/Cards/Smallercards/Smallercards";
import SmallercardsJson from "../Components/Cards/Smallercards/Smallercardsjson";
import CardIndustry from "../Components/Cards/CardIndustry";
import CardInnovation from "../Components/Cards/Innovation/CardInnovation";
import Mainbloghead from "../Components/Cards/Blogcard/mainbloghead";
import Joinusnow from "../Components/Cards/Joinus/Joinus";
import BigCarousel from "../Components/Cards/Carousel/Carousel";
import { useSelector } from "react-redux";
import SlidingCarousel from "../Components/Cards/Carousel/SlidingCarousel";
import { formatDate } from "../config/dateFormat";
import SlidingCarouselnews from "../Components/Cards/Carousel/SlidingCarouselnews";
import Nuggetcard from "../Components/Cards/Innovation/Nuggetcard";

const Landingpage = () => {
  const { newbloglist } = useSelector((state) => state.newsblog);

  useEffect(() => {
    setNewsPosts(newbloglist.filter((items) => items.category === "news"));
    setNuggetPosts(newbloglist.filter((items) => items.category === "nugget"));
    setBlogPosts(newbloglist.filter((items) => items.category === "blog"));
  }, [newbloglist]);

  const [newsPosts, setNewsPosts] = useState([]);
  const [blogPosts, setBlogPosts] = useState([]);
  const [nuggetPosts, setNuggetPosts] = useState([]);

  return (
    <div className="cwa-lndpg-mn-container">
      <BigCarousel />
      <Card />
      <div className="industry-container">
        <CardIndustry />
        <div className="small-cards-mncontainer">
          {SmallercardsJson.map((items) => (
            <Smallercards
              slideImageurl={items.imgurl}
              textslider={items.textname}
              linkurl={items.urlink}
            />
          ))}
        </div>
      </div>

      <div className="innovation-container">
        <CardInnovation />
        <div className="innoovation-card-container" id="news-section">
          <SlidingCarouselnews
            newsPosts={newsPosts}
            header="News Insight"
            buttontxt="All Press Release"
          />
        </div>
      </div>

      <div className="innovation-container">
        <Mainbloghead />
        <div className="innoovation-card-container" id="blog-section">
          <SlidingCarousel
            newsPosts={blogPosts}
            header="Insights Blog"
            buttontxt="Read all blogs"
          />
        </div>
      </div>

      <div className="JoinUs-container">
        <Joinusnow />
        <div className="innoovation-card-container">
          {nuggetPosts.map((post) => (
            <Nuggetcard
              key={post.id}
              slideImageurl={post.imageurl}
              dates={formatDate(post.modified)}
              title={post.title}
              subtitle={post.caption}
              butnlabel={post.buttonlabel}
              linkurl={post.title}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Landingpage;
