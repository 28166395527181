import React, { useState, useEffect } from "react";
import "../pages.css";
import "../Babcock/Babcock.css";
import axios from "axios";
import { FiPlus, FiMinus } from "react-icons/fi";
import { IoIosClose } from "react-icons/io";
import MonthJson from "../Jsonsdata/MonthJson";
import DayJson from "../Jsonsdata/DayJson";
import Socialjs from "../Jsonsdata/Socialjson";
import Dealsjson from "../Jsonsdata/Dealsjson";
import AirlineJson from "../Jsonsdata/AirlineJson";
import Singleselect from "../../Components/dropdown/Singleselect/Singleselect";
import Cities from "./cityjson";
import Dateselector from "../../Components/dropdown/Singleselect/Dateselector";
import Formloader from "../../Components/Formloader/Formloader";
import ReactLoading from "react-loading";
import { API_FETCH, Server_API } from "../../server";

// installed using npm install buffer --save
window.Buffer = window.Buffer || require("buffer").Buffer;
// const FOLDER_ID = "1sBLUFlwmvFHl6E8jqbNNO726P9Mxb9Ux";

const Joinusnow = () => {
  const [elemcounterState, setElemcounterState] = useState(true);
  const [region, setRegion] = useState(false);
  const [deals, setDeals] = useState(false);
  const [airlines, setAirlines] = useState(false);
  const [regionsjson, setRegionsjson] = useState([]);
  const [regionCode, setRegionCode] = useState({
    rc: "",
    rn: "",
  });
  const [iATA, setIATA] = useState(true);
  const [checked, setChecked] = useState(false);
  const [checkeed, setCheckeed] = useState(false);
  const [nANTA, setNANTA] = useState(true);
  const [rowcount, setRowcount] = useState(1);
  const [cityPair, setCityPair] = useState([]);
  const [citycode, setCitycode] = useState("");
  const [submitstatus, setSubmitstatus] = useState(0);
  // const [loaddots, setLoaddots] = useState(false);
  const [erroverlay, setErroverlay] = useState({
    status: false,
    message: "",
  });

  const [contractform, setContractform] = useState({
    office: {
      agency_name: "",
      agency_address: "",
      agency_email: "",
      agency_telephone: "",
      agency_fax: "",
      is_iata_certified: "",
      is_nanta_member: "",
      iata_partner: "",
      country: "",
      region: "",
    },
    contract: {
      number_of_employees: 0,
      number_of_offices: 0,
      net_bookings: 0,
      website: "",
      top_airlines: [],
      travel_deals: [],
      how_you_heard_us: "",
    },
    directors: [
      {
        full_name: "",
        phone: "",
        birthday: "",
        anniversary: "",
      },
    ],
    cac_document: "",
  });

  const [elemcounter, setElemcounter] = useState(1);
  const [cities, setCities] = useState(false);
  const [disable, setDisable] = useState(false);

  const directorinfo = {
    full_name: "",
    phone: "",
    birthday: "",
    anniversary: "",
  };

  const handleonChange = (event) => {
    const { name, value } = event.target;
    const letters_only = /^[A-Za-z\s]*$/;
    const numbers_only = /^[0-9]+$/;
    const numb_char_only = /^[\d+]+$/;

    // if its letter only
    if (name === "agency_name" || name === "iata_partner") {
      if (event.target.value === "" || letters_only.test(event.target.value)) {
        setContractform({
          ...contractform,
          office: {
            ...contractform.office,
            [name]: value,
          },
        });
      }
    }

    // if its number and special character
    else if (name === "agency_telephone" || name === "agency_fax") {
      if (
        event.target.value === "" ||
        numb_char_only.test(event.target.value)
      ) {
        setContractform({
          ...contractform,
          office: {
            ...contractform.office,
            [name]: value,
          },
        });
      }
    }

    // if its number and special character
    else if (name === "agency_address" || name === "agency_email") {
      setContractform({
        ...contractform,
        office: {
          ...contractform.office,
          [name]: value,
        },
      });
    }

    // // if its number only
    else if (name === "is_iata_certified" || name === "is_nanta_member") {
      if (event.target.value === "" || numbers_only.test(event.target.value)) {
        setContractform({
          ...contractform,
          office: {
            ...contractform.office,
            [name]: value,
          },
        });
      }
    }

    // // if its Numbers only
    else if (
      name === "number_of_employees" ||
      name === "number_of_offices" ||
      name === "net_bookings"
    ) {
      if (event.target.value === "" || numbers_only.test(event.target.value)) {
        setContractform({
          ...contractform,
          contract: {
            ...contractform.contract,
            [name]: parseInt(value),
          },
        });
      }
    }

    // // if its number character only
    else if (name === "website") {
      setContractform({
        ...contractform,
        contract: {
          ...contractform.contract,
          [name]: value,
        },
      });
    }

    // // if its letter only
    else if (name === "full_name") {
      if (event.target.value === "" || letters_only.test(event.target.value)) {
        setContractform({
          ...contractform,
          directors: {
            ...contractform.directors,
            [name]: value,
          },
        });
      }
    }

    // if its Numbers and Characters only
    else if (name === "phone") {
      if (
        event.target.value === "" ||
        numb_char_only.test(event.target.value)
      ) {
        setContractform({
          ...contractform,
          directors: {
            ...contractform.directors,
            [name]: value,
          },
        });
      }
    }
  };

  useEffect(() => {
    SetIncoming();
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  const handleFileChange = async (e) => {
    setDisable(true);

    const file = e.target.files[0];
    if (!file) return;

    const formData = new FormData();
    formData.append("file", file);

    try {
      const response = await axios.post(`${API_FETCH}/upload`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      setContractform({ ...contractform, cac_document: response.data.fileUrl });
      setDisable(false);
    } catch (error) {
      setDisable(false);
      console.error("Error uploading file:", error);
    }
  };

  const handleChange = () => {
    setChecked(!checked);
    if (checked === false) {
      setIATA(false);
      setContractform({
        ...contractform,
        office: {
          ...contractform.office,
          iata_partner: "",
        },
      });
    } else {
      setContractform({
        ...contractform,
        office: {
          ...contractform.office,
          is_iata_certified: "",
        },
      });
      setIATA(true);
    }
  };

  const handleChangee = () => {
    setCheckeed(!checkeed);
    if (checkeed === false) {
      setNANTA(false);
    } else {
      setNANTA(true);
    }
  };

  const SetIncoming = async () => {
    try {
      var config = {
        method: "GET",
        url: `${Server_API}/bookandwin/region`,
        // headers: {
        //   Authorization: "Bearer " + cookies.Token
        // }
      };

      await axios(config).then((response) => {
        if (response.data.success === true) {
          setRegionsjson(
            response.data.data.filter(
              (items) =>
                items.region_code !== "CWA_Admin" &&
                items.region_code !== "DEV" &&
                items.region_code !== "CWA_Finance"
            )
          );
        }
      });
    } catch (e) {
      if (e.message.includes("401")) {
        // console.log("error")
      }
      // console.log(e)
    }
  };

  const handleRegionFocus = () => {
    setRegion(true);
    setCities(false);
  };

  const handleCityFocus = () => {
    setRegion(false);
    setCities(true);
  };

  const handleDrpdwnClick = (incoming) => {
    setRegionCode(incoming);
    setContractform({
      ...contractform,
      office: {
        ...contractform.office,
        country: incoming.rn,
      },
    });

    setRegion(false);
    getCitypair(incoming.rc);
  };

  const handleDropClick = (incoming) => {
    setCitycode(incoming.rn);
    setCities(false);
    setContractform({
      ...contractform,
      office: {
        ...contractform.office,
        region: incoming.rn,
      },
    });
  };

  // Sorting Objects
  const getCitypair = (incoming) => {
    setCityPair(Cities.find((objct) => objct.id === incoming).values);
  };

  const handleDealsClick = (e, incoming) => {
    // check If the item to be added already exists. if it already exist returns True (Then Remove the Item), else False(Add the Item)
    if (
      contractform.contract.travel_deals.some(
        (exitstingItem) => exitstingItem === incoming.dels
      ) === false
    ) {
      const updateMonths = [
        ...contractform.contract.travel_deals,
        incoming.dels,
      ];

      setContractform({
        ...contractform,
        contract: {
          ...contractform.contract,
          travel_deals: [...updateMonths],
        },
      });
    } else {
      setContractform({
        ...contractform,
        contract: {
          ...contractform.contract,
          travel_deals: [
            ...contractform.contract.travel_deals.filter(
              (filteredeals) => filteredeals !== incoming.dels
            ),
          ],
        },
      });
    }
    setDeals(false);
  };

  /**********Select Max 3 Airlines************/
  const handleAirClick = (e, incoming) => {
    // check If the item to be added already exists. if it already exist returns True (Then Remove the Item), else False(Add the Item)
    if (
      contractform.contract.top_airlines.some(
        (exitstingItem) => exitstingItem === incoming.dels
      ) === false
    ) {
      if (elemcounter <= 3) {
        setElemcounterState(true);

        // If new Item is added into the multi-select, Increase Counter By 1
        setElemcounter(elemcounter + 1);
        const updateMonths = [
          ...contractform.contract.top_airlines,
          incoming.dels,
        ];

        setContractform({
          ...contractform,
          contract: {
            ...contractform.contract,
            top_airlines: [...updateMonths],
          },
        });
      } else {
        setElemcounterState(false);
      }
    } else {
      setElemcounter(elemcounter - 1); // If new Item is added into the multi-select, decrease Counter By 1
      setContractform({
        ...contractform,
        contract: {
          ...contractform.contract,
          top_airlines: [
            ...contractform.contract.top_airlines.filter(
              (filteredeals) => filteredeals !== incoming
            ),
          ],
        },
      });
    }
    setAirlines(false);
  };

  // Onclick of element close button remove item from array list
  const handleClosedealItem = (e, incoming) => {
    setContractform({
      ...contractform,
      contract: {
        ...contractform.contract,
        travel_deals: [
          ...contractform.contract.travel_deals.filter(
            (filteredeals) => filteredeals !== incoming.itemid
          ),
        ],
      },
    });
  };

  // Onclick of element close button remove item from array list
  const handleCloseAirItem = (e, incoming) => {
    setContractform({
      ...contractform,
      contract: {
        ...contractform.contract,
        top_airlines: [
          ...contractform.contract.top_airlines.filter(
            (filteredeals) => filteredeals !== incoming.itemid
          ),
        ],
      },
    });

    setElemcounter(elemcounter - 1);
    setElemcounterState(true);
  };

  // Submit Form
  const submitform = async (event) => {
    event.preventDefault();
    setErroverlay({
      status: true,
      message: "",
    });
    // setLoaddots(true);

    const objects = {
      ...contractform,
    };

    if (objects.cac_document !== "") {
      try {
        var config = {
          method: "POST",
          url: Server_API,
          data: objects,
        };
        console.log(objects);

        await axios(config).then((response) => {
          if (response.data.success === true) {
            setSubmitstatus(1);
            // setLoaddots(false);
            setErroverlay({
              status: true,
              message: "Submission Successful!",
            });
            clearField();
          }
        });
      } catch (e) {
        setErroverlay({
          status: true,
          message: e.response.data.message,
        });
      }
    } else {
      setErroverlay({
        status: true,
        message: "Ensure all fields are filled",
      });
    }
  };

  const clearField = () => {
    setContractform({
      office: {
        agency_name: "",
        agency_address: "",
        agency_email: "",
        agency_telephone: "",
        agency_fax: "",
        is_iata_certified: "",
        is_nanta_member: "",
        iata_partner: "",
        country: "",
        region: "",
      },
      contract: {
        number_of_employees: 0,
        number_of_offices: 0,
        net_bookings: 0,
        website: "",
        top_airlines: [],
        travel_deals: [],
        how_you_heard_us: "",
      },
      directors: [
        {
          full_name: "",
          phone: "",
          birthday: "",
          anniversary: "",
        },
      ],
      cac_document: "",
    });
  };

  const handleAddDirectors = () => {
    setRowcount(rowcount + 1);
    setContractform({
      ...contractform,
      directors: [...contractform.directors, directorinfo],
    });
  };

  const handleRmvDirectors = () => {
    if (rowcount > 1) {
      setRowcount(rowcount - 1);
    }
  };

  const handleDirector = (e, incoming) => {
    const { name, value } = e.target;
    const obj = {
      ...contractform.directors[incoming],
      [name]: value,
    };

    contractform.directors.splice(incoming, 1, obj);
  };

  const handleDealsFocus = () => {
    setDeals(!deals);
  };

  const handleAirFocus = () => {
    setAirlines(!airlines);
  };

  const handleselectedSocVal = (incomingval) => {
    setContractform({
      ...contractform,
      contract: {
        ...contractform.contract,
        how_you_heard_us: incomingval.val,
      },
    });
  };

  const handleMarriageDay = (incomingval) => {
    const obj = {
      ...contractform.directors[incomingval.index],
      anniversary: incomingval.sdate,
    };

    contractform.directors.splice(incomingval.index, 1, obj);
  };

  const handleBirthdayDay = (incomingval) => {
    const obj = {
      ...contractform.directors[incomingval.index],
      birthday: incomingval.sdate,
    };

    contractform.directors.splice(incomingval.index, 1, obj);
  };

  const handleClose = () => {
    setErroverlay({
      status: false,
      message: "",
    });
    // setLoaddots(false);
  };

  return (
    <form onSubmit={submitform} className="cwa-babckpg-mn-container">
      <Formloader
        overlay={erroverlay}
        submissionstatus={submitstatus}
        closeoverlay={handleClose}
      />

      <div className="toptext-joinus">
        <span>HOME - JOIN US</span>
        <span>Dear Sir/Madam</span>
        <span>
          In order to serve you better and to ensure that you get adequate
          training as regards the scope of your agency's activities, Sabre West
          Africa is embarking on a drive to help maximize the use of Global
          Distribution System (GDS) in Travel businesses all over West Africa.
          Amongst other things, we will provide information on our products,
          services and solutions and ways in which you can position them
          effectively in your organisation. To enable your organisation take
          advantage of this initiative, we would require you to provide the
          below information. We do promise that every information given will be
          treated as confidential.
        </span>
      </div>
      <div className="office-inform-contain">OFFICE INFORMATION</div>
      <div className="office-info-row1">
        <div className="office-info-row1a">
          <label>Agency Name *</label>
          <input
            placeholder="Your Agency Name"
            name="agency_name"
            onChange={handleonChange}
            value={contractform.office.agency_name}
            type="text"
            required
            id="agencyname"
          />
        </div>

        <div className="office-info-row1b">
          <label>Country *</label>
          <div className="bnw-dirname-container">
            <input
              value={regionCode.rn}
              onFocus={handleRegionFocus}
              placeholder="Regions"
              id="bnw-firname-box"
            />
            {region && (
              <div className="bnw-region-drpdwn">
                {regionsjson.map(
                  (item, index) =>
                    item.name !== "Central West Africa ADMIN" && (
                      <span
                        onClick={(e) =>
                          handleDrpdwnClick({
                            rc: item.region_code,
                            rn: item.name,
                          })
                        }
                        key={index}
                      >
                        {item.name}
                      </span>
                    )
                )}
              </div>
            )}
          </div>
        </div>

        <div className="office-info-row1b">
          <label>Office Location City *</label>
          <div className="bnw-dirname-container">
            <input
              value={citycode}
              onFocus={handleCityFocus}
              placeholder="Regions"
              id="bnw-firname-box"
            />
            {cities && (
              <div className="bnw-region-drpdwn">
                {cityPair.map((item, index) => (
                  <span
                    onClick={(e) =>
                      handleDropClick({ rc: item.id, rn: item.label })
                    }
                    key={index}
                  >
                    {item.label}
                  </span>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="office-info-row1">
        <div className="office-info-row1a">
          <label>Agency Address *</label>
          <input
            placeholder="Your Agency Address"
            name="agency_address"
            onChange={handleonChange}
            value={contractform.office.agency_address}
            type="text"
            required
            id="agencyname"
          />
        </div>

        <div className="office-info-row1b">
          <label>Agency Email *</label>
          <input
            placeholder="Email Address"
            name="agency_email"
            onChange={handleonChange}
            value={contractform.office.agency_email}
            type="email"
            required
            id="agencyname"
          />
        </div>
      </div>

      <div className="office-info-row1">
        <div className="office-info-row1c">
          <label>Agency Telephone *</label>
          <input
            placeholder="Agency Telephone"
            name="agency_telephone"
            onChange={handleonChange}
            value={contractform.office.agency_telephone}
            type="tel"
            required
            id="agencyname"
          />
        </div>

        <div className="office-info-row1c">
          <label>Agency Fax *</label>
          <input
            placeholder="Agency Fax"
            name="agency_fax"
            onChange={handleonChange}
            value={contractform.office.agency_fax}
            type="number"
            required
            id="agencyname"
          />
        </div>

        <div className="office-info-row1c">
          <label>
            Are you IATA Certified? *{" "}
            <input
              type="checkbox"
              id="checkboxx"
              checked={checked}
              onChange={handleChange}
            />
          </label>
          <input
            placeholder="IATA Registration Number"
            name="is_iata_certified"
            onChange={handleonChange}
            value={contractform.office.is_iata_certified}
            type="number"
            required
            id="agencyname"
            disabled={iATA}
          />
        </div>

        <div className="office-info-row1c">
          <label>
            Are you registered with NANTA? *{" "}
            <input
              type="checkbox"
              id="checkboxx"
              checked={checkeed}
              onChange={handleChangee}
            />
          </label>
          <input
            placeholder="Agency NANTA Number"
            name="is_nanta_member"
            onChange={handleonChange}
            value={contractform.office.is_nanta_member}
            type="number"
            required
            id="agencyname"
            disabled={nANTA}
          />
        </div>
      </div>

      {iATA ? (
        <div className="office-info-row1">
          <div className="office-info-row1c">
            <label>If non-IATA, then advise IATA partner agent *</label>
            <input
              placeholder="IATA Partner Agent"
              name="iata_partner"
              onChange={handleonChange}
              value={contractform.office.iata_partner}
              type="text"
              required
              id="agencyname"
            />
          </div>
        </div>
      ) : (
        ""
      )}

      <div className="dashedunderline" />

      <div className="office-inform-contain">DIRECTORS</div>

      <div className="addremove-directors">
        <div className="add-directors" onClick={handleAddDirectors}>
          <div className="cross-icon-container">
            <FiPlus id="cross-icon" />
          </div>
          Add Row
        </div>

        <div className="add-directors" onClick={handleRmvDirectors}>
          <div className="cross-icon-container">
            <FiMinus id="cross-icon" />
          </div>
          Delete Row
        </div>
      </div>

      <div className="directors-container">
        {[...Array(rowcount)].map((item, index) => {
          return (
            <div key={index} className="office-info-row1">
              <div className="office-info-row1c">
                <label>Director's Fullname *</label>
                <input
                  name="full_name"
                  placeholder="Fullname of Director"
                  type="text"
                  required
                  id="agencyname"
                  onChange={(e) => handleDirector(e, index)}
                />
              </div>

              <div className="office-info-row1c">
                <label>Director's Phone Number *</label>
                <input
                  name="phone"
                  placeholder="Directors Phone Number"
                  type="tel"
                  required
                  id="agencyname"
                  onChange={(e) => handleDirector(e, index)}
                />
              </div>

              <div className="office-info-row1c">
                <label className="mb-3">Director's Birthday Aniversary *</label>
                <div className="bday-anniver">
                  <Dateselector
                    check={index}
                    name="bday"
                    selectedSocVal={handleBirthdayDay}
                    itemListmon={MonthJson}
                    itemListday={DayJson}
                  />
                </div>
              </div>

              <div className="office-info-row1c">
                <label className="mb-3">Director's Marriage Aniversary *</label>
                <div className="bday-anniver">
                  <Dateselector
                    check={index}
                    name="bday"
                    selectedSocVal={handleMarriageDay}
                    itemListmon={MonthJson}
                    itemListday={DayJson}
                  />
                </div>
              </div>
            </div>
          );
        })}
      </div>

      <div className="office-inform-contain">CONTRACT INFORMATION</div>

      <div className="contactinfo-container">
        <div className="office-info-row1">
          <div className="office-info-row1d">
            <label>Number of Employees *</label>
            <input
              placeholder="Number of Employees"
              name="number_of_employees"
              onChange={handleonChange}
              value={contractform.contract.number_of_employees}
              type="number"
              required
              id="agencyname"
            />
          </div>

          <div className="office-info-row1d">
            <label>Number of Office Locations *</label>
            <input
              placeholder="Number of Office Locations"
              name="number_of_offices"
              onChange={handleonChange}
              value={contractform.contract.number_of_offices}
              type="number"
              required
              id="agencyname"
            />
          </div>

          <div className="office-info-row1d">
            <label>Net Bookings Per Month/Location *</label>
            <input
              placeholder="Net Bookings Per Month/Location"
              name="net_bookings"
              onChange={handleonChange}
              value={contractform.contract.net_bookings}
              type="number"
              required
              id="agencyname"
            />
          </div>
        </div>

        <div className="office-info-row1">
          <div className="office-info-row1cd">
            <label>Your Website Address *</label>
            <input
              placeholder="Your Website Address"
              name="website"
              onChange={handleonChange}
              value={contractform.contract.website}
              type="text"
              required
              id="agencyname"
            />
          </div>
        </div>

        <div className="office-info-row1">
          <div className="office-info-row1d">
            <label>Top 3 Airlines you Book *</label>
            <div id="agencynames" onClick={handleAirFocus}>
              {contractform.contract.top_airlines.map((item, index) => {
                return (
                  <span id="deals-result" key={index}>
                    {item}
                    <IoIosClose
                      id="close-deals"
                      onClick={(e) => handleCloseAirItem(e, { itemid: item })}
                    />
                  </span>
                );
              })}

              {elemcounterState ? (
                <>
                  {airlines && (
                    <div className="bnw-deals-drpdwn">
                      {AirlineJson.map((item, key) => (
                        <span
                          onClick={(e) =>
                            handleAirClick(e, {
                              key: item.id,
                              dels: item.value,
                            })
                          }
                          key={key}
                        >
                          {item.value}
                        </span>
                      ))}
                    </div>
                  )}
                </>
              ) : (
                <div className="bnw-deals-drpdwn">
                  <span>You Can Only add 3 items</span>
                </div>
              )}
            </div>
          </div>

          <div className="office-info-row1d">
            <label>Travel deals you are interested in *</label>
            <div id="agencynames" onClick={handleDealsFocus}>
              {contractform.contract.travel_deals.map((item, index) => {
                return (
                  <span id="deals-result" key={index}>
                    {item}
                    <IoIosClose
                      id="close-deals"
                      onClick={(e) => handleClosedealItem(e, { itemid: item })}
                    />
                  </span>
                );
              })}

              {deals && (
                <div className="bnw-deals-drpdwn">
                  {Dealsjson.map((item, index) => (
                    <span
                      onClick={(e) =>
                        handleDealsClick(e, { key: item.id, dels: item.value })
                      }
                      key={index}
                    >
                      {item.value}
                    </span>
                  ))}
                </div>
              )}
            </div>
          </div>

          <div className="office-info-row1d">
            <label id="drpdwn-label">How did you hear about us? *</label>
            <Singleselect
              selectedSocVal={handleselectedSocVal}
              itemList={Socialjs}
              placeholder="Media"
            />
          </div>
        </div>

        <div className="office-info-row1">
          <div className="office-infos-row1d">
            <label>
              Upload your Company Registration Certificate (Max of 2MB) *
            </label>
            <label>Please ensure that the Certificate is stamped</label>
            <div id="agencynames_ss">
              <input
                disabled={disable ? true : false}
                type="file"
                name="avatar"
                id="file-input"
                accept=".jpg,.jpeg,.png, .pdf"
                onChange={handleFileChange}
                className="sr-only group relative w-full h-[40px] flex justify-center py-2 px-4 text-sm font-medium rounded-md text-white cursor-pointer"
              />
            </div>
          </div>
        </div>

        <div className="office-inform-contain">
          <span>
            By submitting this form, you agree to accept the{" "}
            <a href="https://www.sabre.com/about/privacy/">
              terms and conditions
            </a>{" "}
            of Sabre West Africa.
          </span>

          <div className="button-plus-loader-container">
            <button
              disabled={disable ? true : false}
              className="rounded-sm text-[11px]"
              type="submit"
            >
              Submit Form
            </button>
            {/* {loaddots && <div className="dots-1"></div>} */}
            {disable && <ReactLoading type="bubbles" color="#00bfff" />}
          </div>
        </div>
      </div>
    </form>
  );
};

export default Joinusnow;
