const slideImages = [
    {
      url: '/assets/4.jpg',
      caption: 'Slide 2',
      heading: "ACCELERATING  INNOVATION",
      subheading: 'To help advance the travel industry with next-gen solutions, Sabre is building a cloud-based technology environment to unlock greater efficiencies and new capabilities. Learn how this digital transformation benefits you.' 
    },
    {
        url: '/assets/5.jpg',
        caption: 'Slide 1',
        heading: "THE FUTURE IS OPEN",
        subheading: 'Open for retailing your way. Open for business today, with the tech you need for tomorrow.' 
    },
    {
      url: '/assets/2.jpg',
      caption: 'Slide 3',
      heading: "Powering better travel experience through technology",
      subheading: 'Our vision is to make traveling seemless for all travellers, thereby promoting better expereince'
    }
];

export default slideImages