import React, { useState, useEffect } from "react";
import "./aboutus.css";
import "../Executiveteam/Executiveteam.css";
import { Link } from "react-router-dom";
import { BsChevronDoubleRight } from "react-icons/bs";
import Picturecard from "../../Components/Cards/Picturecard/Picturecard";
import ExecteamContent from "../Executiveteam/ExecteamContent";
import ExextempixJson from "../Jsonsdata/ExextempixJson";
import { useLocation } from "react-router-dom";
import axios from "axios";

const Aboutus = () => {
  const location = useLocation();
  const API = "https://admin.sabrecwa.com/sitebackend";
  const data = location.state !== null ? location.state.mydata : 2;
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    if (data === 1) {
      handleExeClick();
    } else {
      handleAboutClick();
      // handleExecutiveClick()
    }
  }, [data]);

  const [swipe, setSwipe] = useState(true);
  const [pictureclick, setPictureclick] = useState(0);
  const [aboutus, setAboutus] = useState();
  const [executives, setExecutives] = useState([]);
  const [execobject, setExecobject] = useState({
    executive_name: "",
    qualifications: "",
    position_held: "",
    image_url: "",
    brief_about: "",
  });

  const handleAboutClick = async () => {
    setPictureclick(0);
    try {
      var config = {
        method: "GET",
        url: `${API}/site/executive`,
      };
      console.log(config);

      await axios(config).then((response) => {
        if (response.data.success === true) {
          console.log(response.data);
          setAboutus(response.data.data);
          setExecutives(response.data.data.executives);
        }
      });
    } catch (e) {
      if (e.message.includes("401")) {
        // console.log("error")
      }
      // console.log(e)
    }
  };

  const handleswipe = () => {
    setSwipe(!swipe);
  };

  const handlepictureClick = (e, incoming) => {
    setPictureclick(2);
    executives
      .filter((item) => item.ID === incoming)
      .map((items) =>
        setExecobject({
          executive_name: items.exenexecutive_nameame,
          qualifications: items.qualifications,
          position_held: items.position_held,
          image_url: items.image_url,
          brief_about: items.brief_about,
        })
      );
  };

  const handleExeClick = () => {
    setPictureclick(1);
  };

  const handleTrademark = () => {
    window.open(
      "https://www.sabre.com/about/trademark/",
      "_blank",
      "noopener,noreferrer"
    );
  };

  const handleAllianceClick = () => {
    window.open(
      "https://www.sabre.com/about/joint-ventures-independent-distributors/",
      "_blank",
      "noopener,noreferrer"
    );
  };

  // const handleExecutiveClick  = async () =>{
  //   setPictureclick(0)
  //   try{
  //     var config = {
  //       method: 'GET',
  //       url: `${API}/site/static`
  //     };

  //   await axios(config).then((response) => {
  //   if(response.data.success === true){
  //     setAboutus(response.data.data)
  //   }});}
  //   catch(e){
  //     if(e.message.includes('401')){
  //       // console.log("error")
  //     }
  //     // console.log(e)
  //   }
  // }

  return (
    <div className="cwa-contactuspg-mn-container">
      <div className="cwa-contactus-img">
        <div className="cwa-container-textbox">
          <div id="navigator">
            <span>Home &#187; About</span>
          </div>
          <span>About Us</span>
        </div>
      </div>

      <div className="blogpagee-container-bottm">
        <div
          className={
            swipe ? "exec-left-div-blogpage" : "exec-left-div-blogpage-swipe"
          }
        >
          <div className="exec-left-blog-category">
            <div
              className="exec-left-blog-subcategory"
              onClick={handleAboutClick}
            >
              <span>ABOUT US</span>
            </div>

            <div
              className="exec-left-blog-subcategory"
              onClick={handleAllianceClick}
            >
              <span>ALLIANCES</span>
            </div>

            <Link to="/contact-us" className="exec-left-blog-subcategory">
              <Link to="/contact-us" className="contactus-icon">
                <span>CONTACT US</span>
              </Link>
            </Link>

            <div
              className="exec-left-blog-subcategory"
              onClick={handleExeClick}
            >
              <span>EXECUTIVE TEAM</span>
            </div>

            <div
              className="exec-left-blog-subcategory"
              onClick={handleTrademark}
            >
              <span>COPYRIGHT AND TRADEMARK</span>
            </div>
          </div>
        </div>

        {pictureclick === 0 ? (
          <div
            className="exec-right-div-blogpage"
            dangerouslySetInnerHTML={{ __html: aboutus }}
          />
        ) : pictureclick === 1 ? (
          <div className="exec-right-div-blogpage">
            <div className="exe-team-head">
              <span>Executive Team</span> <span>[Sabre West Africa]</span>
            </div>
            <div className="exe-team-body-container">
              {executives.map((items) => (
                <Picturecard
                  pictureclick={(e) => handlepictureClick(e, items.ID)}
                  name={items.executive_name}
                  title={items.qualifications}
                  imgurl={items.image_url}
                  exepost={items.position_held}
                />
              ))}
            </div>
          </div>
        ) : (
          <div className="exec-right-div-blogpage">
            <div className="exe-team-head">
              <span>Executive Team</span> <span>[Sabre West Africa]</span>
            </div>
            <div className="exe-team-body-container">
              <ExecteamContent
                picturl={execobject.image_url}
                execname={execobject.executive_name}
                exectitle={execobject.qualifications}
                aboutexec={execobject.brief_about}
                exepost={execobject.position_held}
              />
            </div>
          </div>
        )}
      </div>

      <div className="chevron-right-exec-cont" onClick={handleswipe}>
        <BsChevronDoubleRight id="chevron-right-exec" />
      </div>
    </div>
  );
};

export default Aboutus;
