const Cities = [
  {
    id: "CI",
    values: [{ id: 1, label: "Cote D' Voir" }],
  },
  {
    id: "NG",
    values: [
      { id: 1, label: "Abuja" },
      { id: 2, label: "Akure" },
      { id: 3, label: "Apapa" },
      { id: 4, label: "Aspamda" },
      { id: 5, label: "Ibadan" },
      { id: 6, label: "Ikeja" },
      { id: 7, label: "Ikorodu" },
      { id: 8, label: "Marina" },
      { id: 9, label: "Onitsha" },
      { id: 10, label: "Porthacourt" },
      { id: 11, label: "Sura" },
      { id: 10, label: "TBS" },
      { id: 11, label: "Victoria Island" },
    ],
  },
  {
    id: "SN",
    values: [{ id: 1, label: "Senegal" }],
  },
  {
    id: "GH",
    values: [{ id: 1, label: "Ghana" }],
  },
  {
    id: "GA",
    values: [{ id: 1, label: "Gabon" }],
  },
  {
    id: "ML",
    values: [{ id: 1, label: "Mali" }],
  },
  {
    id: "TG",
    values: [{ id: 1, label: "Togo" }],
  },
  {
    id: "NE",
    values: [{ id: 1, label: "Niger" }],
  },
  {
    id: "LR",
    values: [{ id: 1, label: "Liberia" }],
  },
  {
    id: "MR",
    values: [{ id: 1, label: "Mauritania" }],
  },
  {
    id: "BJ",
    values: [{ id: 1, label: "Benin" }],
  },
  {
    id: "BF",
    values: [{ id: 1, label: "Burkina Faso" }],
  },
  {
    id: "CM",
    values: [{ id: 1, label: "Cameroon" }],
  },
];

export default Cities;
