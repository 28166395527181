export const Responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 1250 },
    items: 5,
    slidesToSlide: 1,
  },
  desktop: {
    breakpoint: { max: 2000, min: 1530 },
    items: 5,
    slidesToSlide: 1,
  },
  tablet: {
    breakpoint: { max: 1500, min: 1230 },
    items: 4,
    slidesToSlide: 1,
  },
  subtablet: {
    breakpoint: { max: 1230, min: 930 },
    items: 3,
    slidesToSlide: 1,
  },
  mobile: {
    breakpoint: { max: 930, min: 630 },
    items: 2,
    slidesToSlide: 1,
  },
  minimobile: {
    breakpoint: { max: 630, min: 0 },
    items: 1,
    slidesToSlide: 1,
  },
};
