import axios from "axios";
import { API_FETCH } from "../../server";

// get All News/Blog feed
export const getNewsblogdata = () => async (dispatch) => {
  try {
    dispatch({
      type: "getNewsBlog",
    });

    const { data } = await axios.get(`${API_FETCH}/blog/fetch`);
    dispatch({
      type: "getNewsBlogSuccess",
      payload: data.data,
    });
  } catch (error) {
    dispatch({
      type: "getNewsBlogFailed",
      payload: error.response.data.message,
    });
  }
};
