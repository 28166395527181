import React from 'react'
import './Smallercards.css'

const Smallercards = ({slideImageurl, textslider, linkurl}) => {

  return (
    <a href={linkurl} rel="noreferrer" className='smaller-card-wrapper'>
      <div className='smaller-cards-container' style={{ backgroundImage: `url(${slideImageurl}) `}}>{textslider}</div>
    </a>
  )
}

export default Smallercards