const Dealsjson = [
    {
        id: 1, 
        value: "Flight"
    },
    {
        id: 2, 
        value: "Hotel"
    },
    {
        id: 3, 
        value: "Cruise"
    },
    {
        id: 4, 
        value: "Tour"
    },
    {
        id: 5, 
        value: "Leisure"
    }
]

export default Dealsjson