import React from 'react'
import '../cards.css'

const Mainbloghead = () => {
  return (
    <div className='card-blog-container'>
        <div className='col-innov-content-subinner'/>
        {/* <div className='col-blog-img-subinner'/> */}
        <div className='col-innov-content-inner'>
            <div className='red-line-icon'/>
            <div className="container-sub-indust">
                <span className="indust-innov-top-text">Latest News</span>
                <span className="indust-innov-bottm-text">Industry leading articles, announcements, research, expert insights and newsroom updates on the cutting edge trends, stories and innovations shaping the future of travel.</span>
            </div>
        </div>
    </div>
  )
}

export default Mainbloghead