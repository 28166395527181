import React from 'react'
import './Picturecard.css'

const Picturecard = ({name, title, imgurl, exepost, pictureclick}) => {
  const handleClick = () =>{
    pictureclick()
  }
  return (
    <div className='picture-card-container' onClick={handleClick}>
        <div className='picturecard' style={{ backgroundImage: `url(${imgurl})`}}/>
        <div className='text-cardcontainer'>
            <span>{name}</span>
            <span>{title}</span>
            <span>{exepost}</span>
        </div>
    </div>
  )
}

export default Picturecard