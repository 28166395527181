const Socialjs = [
    {
        id: 1, 
        value: "Facebook"
    },
    {
        id: 2, 
        value: "Google Ads"
    },
    {
        id: 3, 
        value: "Google Search"
    },
    {
        id: 4, 
        value: "Youtube"
    },
    {
        id: 5, 
        value: "Referred By Agency"
    },
    {
        id: 6, 
        value: "Sabre Staff"
    },
    {
        id: 7, 
        value: "Others"
    }
]

export default Socialjs