import React from 'react'
import './Formloader.css'
import {BsPatchCheckFill} from 'react-icons/bs'
import {AiFillCloseCircle} from 'react-icons/ai'

const Formloader = ({overlay, submissionstatus, closeoverlay}) => {
    const closeOverlay = () =>{
        closeoverlay()
    }
  return (
    <>
        {overlay.status && <div className="babcock_loading_overlay">
            {submissionstatus === 1 ? <div className="overlay-submit-successful">
                <BsPatchCheckFill id='submit-checkicon'/>
                {overlay.message}
                <AiFillCloseCircle id='submit-checkicon' onClick={closeOverlay}/>
            </div> : <div className="overlay-submit-failed">
                <BsPatchCheckFill id='submit-checkicon'/>
                {overlay.message}
                <AiFillCloseCircle id='failed-checkicon' onClick={closeOverlay}/>
            </div>}
        </div>}
    </>
  )
}

export default Formloader