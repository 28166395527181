import React, { useState, useEffect } from "react";
import "./viewMoreNews.css";
// import { useNavigate } from "react-router-dom";
import InnovationSmCard from "./Innovationsmallercard";
import { formatDate } from "../../../config/dateFormat";
import { useSelector } from "react-redux";
import SearchField from "../../Search";

const ViewMoreNews = () => {
  const [newsPosts, setNewsPosts] = useState([]);
  const [more, setMore] = useState(12);

  const { newbloglist } = useSelector((state) => state.newsblog);
  const { datalist } = useSelector((state) => state.datalist);

  useEffect(() => {
    setNewsPosts(newbloglist.filter((item) => item.category === "news"));
  }, [newbloglist]);

  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  const handleNewsClick = () => {};

  const handleViewMore = () => {
    setMore((prevMore) => prevMore + 12);
  };

  return (
    <div className="container-body mb-9">
      <div className="w-full mt-[40px] mb-[30px] max-w-[800px] flex flex-col gap-3">
        <h1 className="text-[2em] font-bold">Press Releases</h1>
        <p className="text-[15px]">
          Recent announcements and official releases on how Sabre is changing
          the face of travel technology. Have a question? Reach out to our media
          team.
        </p>
      </div>

      <SearchField data={newsPosts} />
      <div>
        <div className="flex w-full h-fit px-6 flex-wrap gap-2 justify-center">
          {datalist
            ?.slice() // Create a copy of the array before sorting
            ?.sort((a, b) => new Date(b.modified) - new Date(a.modified))
            ?.slice(0, more) // Show only the first "more" number of items
            ?.map((post) => (
              <InnovationSmCard
                key={post.id}
                slideImageurl={post.imageurl}
                dates={formatDate(post.modified)}
                title={post.title}
                subtitle={post.caption}
                butnlabel={post.buttonlabel}
                buttonclicked={() => handleNewsClick(post.id)}
                linkurl={post.title}
              />
            ))}
        </div>
        {newsPosts.length > more && (
          <button
            onClick={handleViewMore}
            className="text-[13px] text-[white] bg-[#E50000] rounded-md border-black hover:bg-[#920000] hover:text-[#d1b1b1]"
          >
            View More
          </button>
        )}
      </div>
    </div>
  );
};

export default ViewMoreNews;
