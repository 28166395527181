const AirlineJson = [
    {
        id: 1, 
        value: "Air France"
    },
    {
        id: 2, 
        value: "Arik Air"
    },
    {
        id: 3, 
        value: "British Airways"
    },
    {
        id: 4, 
        value: "China Southern Airlines"
    },
    {
        id: 5, 
        value: "Ethopian Airlines"
    },
    {
        id: 6, 
        value: "Etihad Airways"
    },
    {
        id: 7, 
        value: "IBERIA"
    },
    {
        id: 8, 
        value: "Kenya Airways"
    },
    {
        id: 9, 
        value: "KLM"
    },
    {
        id: 10, 
        value: "Lufthansa"
    },
    {
        id: 11, 
        value: "Lufthansa Cargo"
    },
    {
        id: 12, 
        value: "MEA"
    },
    {
        id: 13, 
        value: "Qatar Airways"
    },
    {
        id: 14, 
        value: "Royal Air Maroc"
    },
    {
        id: 15, 
        value: "Royal Jordanian"
    },
    {
        id: 16, 
        value: "RwandAir"
    },
    {
        id: 17, 
        value: "SAA"
    },
    {
        id: 18, 
        value: "THY - Turkish Airlines"
    },
    {
        id: 20, 
        value: "United Airlines"
    },
    {
        id: 21, 
        value: "Virgin Atlantic"
    }
]

export default AirlineJson