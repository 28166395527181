import React from "react";
import { FaFacebookF, FaLinkedinIn, FaTwitter } from "react-icons/fa";

const Socials = () => {
  return (
    <div className="social-media-links w-[90%]">
      <a
        href="https://www.linkedin.com/shareArticle?url=YOUR_URL"
        target="_blank"
        rel="noopener noreferrer"
      >
        <FaLinkedinIn size={18} />
      </a>

      <a
        href="https://www.facebook.com/sharer/sharer.php?u=YOUR_URL"
        target="_blank"
        rel="noopener noreferrer"
      >
        <FaFacebookF size={18} />
      </a>

      <a
        href="https://twitter.com/intent/tweet?url=YOUR_URL"
        target="_blank"
        rel="noopener noreferrer"
      >
        <FaTwitter size={18} />
      </a>
    </div>
  );
};

export default Socials;
