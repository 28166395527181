const SmallercardsJson = [
    {
        imgurl: "https://images.unsplash.com/photo-1526223352238-fef4f40bbef8?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTh8fGFpcmxpbmVzfGVufDB8MXwwfHw%3D&auto=format&fit=crop&w=500&q=60",
        textname: "Airlines",
        urlink: "https://www.sabre.com/industries/#airlines"
    },
    {
        imgurl: "https://images.unsplash.com/photo-1605713288610-00c1c630ca1e?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MjB8fGZhbWlseXxlbnwwfDF8MHx8&auto=format&fit=crop&w=500&q=60",
        textname: "Agencies",
        urlink: "https://www.sabre.com/industries/#agencies"
    },
    {
        imgurl: "https://images.unsplash.com/photo-1546412414-e1885259563a?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=387&q=80",
        textname: "Hospitality",
        urlink: "https://www.sabre.com/industries/#hospitality"
    },
    {
        imgurl: "https://images.unsplash.com/photo-1552733407-5d5c46c3bb3b?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8NXx8dHJhdmVsfGVufDB8MXwwfHw%3D&auto=format&fit=crop&w=500&q=60",
        textname: "Ground, Sea & Tour Operators",
        urlink: "https://www.sabre.com/industries/#leisure"
    },
    {
        imgurl: "https://images.unsplash.com/photo-1574073763042-9dbe6ae03853?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=387&q=80",
        textname: "Developers",
        urlink: "https://www.sabre.com/industries/#developers"
    }
]

export default SmallercardsJson