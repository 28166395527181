import React from "react";

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

import { Responsive } from "./responsive";
import { formatDate } from "../../../config/dateFormat";
import { useNavigate } from "react-router-dom";
import Blogcard from "../Blogcard/Blogcard";

const SlidingCarousel = ({ newsPosts, header, buttontxt }) => {
  const navigate = useNavigate();

  const handleNewsBtnClick = () => {
    navigate("/insights/news");
  };

  const handleBlogBtnClick = () => {
    navigate("/insights/blog");
  };

  return (
    <div className="w-full flex flex-col gap-2">
      <div className="w-full flex justify-between items-center ">
        <span className="text-[25px] text-[#333] font-[600]">{header}</span>

        {buttontxt !== "" && (
          <div
            onClick={
              buttontxt === "All Press Release"
                ? handleNewsBtnClick
                : handleBlogBtnClick
            }
            className="py-2 px-4 text-[14px] border-1 border-black text-[#333] font-semibold cursor-pointer hover:border-[#e50000] hover:text-[#e50000]"
          >
            {buttontxt}
          </div>
        )}
      </div>

      <div className="w-full flex bg-[white]">
        {newsPosts && newsPosts?.length !== 0 && (
          <Carousel
            className="absolute w-full flex justify-between p-1  bg-transparent"
            minimumTouchDrag={20}
            responsive={Responsive}
            slidesToSlide={1}
            infinite={true}
            transitionDuration={15}
            rewind={true}
            autoPlay
            rewindWithAnimation={true}
            shouldResetAutoplay={false}
          >
            {newsPosts
              ?.sort((a, b) => new Date(b.modified) - new Date(a.modified))
              ?.slice(0, 12)
              ?.map((post) => (
                <Blogcard
                  key={post.id}
                  slideImageurl={post.imageurl}
                  dates={formatDate(post.modified)}
                  title={post.title}
                  subtitle={post.caption}
                  butnlabel={post.buttonlabel}
                  linkurl={post.title}
                />
              ))}
          </Carousel>
        )}
      </div>
    </div>
  );
};

export default SlidingCarousel;
