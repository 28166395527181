export const formatDate = (isoDate) => {
  const date = new Date(isoDate);

  const options = { year: "numeric", month: "long", day: "numeric" };
  return date.toLocaleDateString("en-US", options);
};

// Example usage:
const formattedDate = formatDate("2024-08-08T12:11:33.000Z");
console.log(formattedDate); // Output: "August 8, 2024"
