// ResourceContent.js
import React from "react";
import ResourceCard from "../../Components/ResourceCard/ResourceCard";
import ButtonPagenate from "../../Components/ButtonPagenate/ButtonPagenate";

const ResourceContent = ({
  data,
  itemsPerPage,
  pageNumber,
  setPageNumber,
  resourceTitle,
}) => {
  const pageCount = Math.ceil(data.length / itemsPerPage);
  const pagesVisited = pageNumber * itemsPerPage;

  const newPagenum = (num) => {
    setPageNumber(num);
  };

  return (
    <div className="exec-right-div-blpage">
      <div className="res-team-head">
        <span>{resourceTitle}</span>
      </div>
      <div className="res-team-body-container">
        {data.slice(pagesVisited, pagesVisited + itemsPerPage).map((item) => (
          <ResourceCard
            key={item.id || item.isbn13}
            title={item.title}
            imgurl={item.img_url || item.image}
            pdfUrl={item.file_url} // Optional for Videos
            fullobj={item}
          />
        ))}
      </div>
      <ButtonPagenate
        updatedPageNum={newPagenum}
        pageCount={pageCount}
        pageNumber={pageNumber}
        previousLabel="Prev"
        nextLabel="Next"
      />
    </div>
  );
};

export default ResourceContent;
