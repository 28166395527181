import React from 'react'
import './cards.css'

const Card = () => {
  return (
    <div className='card-main-container'>
        <div className='card-main-container-left'>
            <div className='left-container'>
                <span>Together, <br/>we make travel happen</span>
                <span>We are a software and technology company that powers the global travel industry. With decades of revolutionary firsts, our team of experts drive innovation and ingenuity in the industry. Learn more about how we help customers operate more efficiently, drive revenue and offer personalized traveler experiences with next-generation technology solutions.</span>
                <button id='aboutuss-butn'>ABOUT US</button>
            </div>
        </div>
        <div className='card-main-container-right'>
            <div className='left-container-img'/>
        </div>
    </div>
  )
}

export default Card