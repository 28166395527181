// Get search response
export const searchResponse = (datalist) => ({
  type: "searchData",
  payload: datalist,
});

// get All News/Blog feed
export const getResourcesdata = (resourcedata) => ({
  type: "searchResourceData",
  payload: resourcedata,
});
