import React from "react";
import "../Blogcard/Blogcard.css";
import { Link } from "react-router-dom";

const InnovationSmCard = ({
  slideImageurl,
  subtitle,
  dates,
  title,
  buttonclicked,
  butnlabel,
  linkurl,
}) => {
  const registeredClick = () => {
    buttonclicked(true);
  };

  return (
    <div
      className="overflow-hidden h-[80vh] mr-2 min-w-[250px] inline-flex flex-col items-center justify-start shadow-md hover:shadow-lg shadow-[rgba(0,0,0,0.2)] transition-all duration-300 ease-in-out bg-white flex-grow max-w-[400px] rounded-[10px] flex-1 gap-2 p-2 hover:relative hover:-top-0.5 pict_container"
      onClick={registeredClick}
    >
      <div className="imgdiv-container-contain">
        <div
          className="imgdiv-container"
          style={{
            backgroundImage: `url(${process.env.PUBLIC_URL + slideImageurl})`,
          }}
        />
      </div>

      <div className="flex flex-col flex-1 items-start w-full overflow-visible justify-start relative">
        <h3 className="text-[12px] text-[#8888] mt-[10px]">{dates}</h3>

        <div className="w-full flex flex-col py-2 gap-2">
          <div className="bg-text-container_">
            <span id="bg-title">{title}</span>
          </div>
          <div className="bg-text-container">
            <div className="bg-text-wrapper">
              <span id="bg-name">{subtitle}</span>
            </div>
          </div>
        </div>

        <Link
          to={`/news/insights/${linkurl}`}
          className="flex justify-center h-fit absolute bottom-0"
        >
          <div className="bg-[#e50000] py-2 px-3 text-[white] text-[11px] rounded-md">
            {butnlabel}
          </div>
        </Link>
      </div>
    </div>
  );
};

export default InnovationSmCard;
