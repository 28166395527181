import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import "./fullBlogPost.css";
import { useSelector } from "react-redux";
import { formatDate } from "../../config/dateFormat";
import Socials from "../../Components/Socials";
import "react-quill/dist/quill.snow.css";
import SlidingCarousel from "../../Components/Cards/Carousel/SlidingCarousel";

const FullBlogPost = () => {
  const { newbloglist } = useSelector((state) => state.newsblog);
  const [data, setData] = useState({});
  const { blogtitle } = useParams();
  const [blogPosts, setBlogPosts] = useState([]);

  useEffect(() => {
    setBlogPosts(newbloglist.filter((items) => items.category === "blog"));
  }, [newbloglist]);

  useEffect(() => {
    const filteredData = newbloglist?.find(
      (newsitems) => newsitems.title === blogtitle
    );
    setData(filteredData);
  }, [blogtitle, newbloglist]);

  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  if (!data) {
    return <div>Post not found</div>;
  }

  return (
    <div className="flex flex-col gap-2 justify-start items-center">
      <div className="w-full flex flex-col justify-center px-4 py-2">
        <div className="w-full flex justify-center gap-5 min-h-[70vh]  flex-wrap items-center h-fit">
          <div className="flex flex-col gap-4 items-start w-[30%] min-w-[320px] h-fit flex-grow max-w-[80%]">
            <span className="text-[30px] font-bold">{data.title}</span>
            <span className="text-[18px] font-light">{data.caption}</span>
          </div>

          <div className="max-w-[800px] min-w-[320px] h-[100%] w-[55%] rounded-full overflow-hidden">
            {data.imageurl && (
              <img
                src={data.imageurl}
                alt={data.title}
                className="w-full h-fit"
              />
            )}
          </div>
        </div>

        <div className="flex gap-2 items-center">
          <span className="text-[14px] font-semibold">Published on: </span>
          <span className="text-[14px] text-[#8888]">
            {formatDate(data.modified)}
          </span>
        </div>
      </div>

      <hr />

      <div className="w-full flex gap-1 px-4 ql-editor">
        <div className="max-w-[300px] w-[40%]"></div>
        <div
          className="flex-1 min-w-[350px] pr-8 py-2 flex flex-wrap"
          dangerouslySetInnerHTML={{ __html: data.text }}
        />
      </div>

      <Socials />

      <div className="w-[90%] bg-[#f5f5f5] flex justify-center items-center mb-9">
        <SlidingCarousel
          newsPosts={blogPosts}
          header="More Blogs"
          buttontxt=""
        />
      </div>
    </div>
  );
};

export default FullBlogPost;
