import React, { useState, useEffect } from "react";
import { IoMdSearch } from "react-icons/io";
import { Store } from "../Redux/store";
import { searchResponse } from "../Redux/Actions/searchResponse";
import "../Pages/Blogpage/viewMoreBlog.css"

const SearchField = ({ data }) => {
  const [query, setQuery] = useState("");

  useEffect(() => {
    // Dispatch the full data on mount or when `data` changes
    Store.dispatch(searchResponse(data));
  }, [data]);

  const handleSearch = (value) => {
    if (value !== "") {
      const filteredData = data.filter((item) =>
        ["title", "text", "caption"].some((key) =>
          item[key]?.toLowerCase().includes(value)
        )
      );
      Store.dispatch(searchResponse(filteredData));
    } else {
      // If the search field is empty, send all data
      Store.dispatch(searchResponse(data));
    }
  };

  const handleChange = (event) => {
    let value = event.target.value.toLowerCase();
    setQuery(event.target.value.toLowerCase());
    // if (value === "") {
    handleSearch(value);
    // }
  };

  return (
    <div className="max-w-[800px] mb-[30px] w-full flex justify-between items-center gap-0.5 overflow-hidden">
      <input
        type="text"
        className="p-3 text-[13px] w-full rounded-l-xl searchbarcontainer outline-none focus:outline-none"
        placeholder="Site Search"
        value={query}
        onChange={handleChange}
      />
      <div
        className="flex items-center justify-center w-[60px] h-13 p-3 text-white bg-red-600 border-y focus:outline-none rounded-r-xl cursor-pointer"
        onClick={handleSearch}
      >
        <IoMdSearch color="white" size={20} />
      </div>
    </div>
  );
};

export default SearchField;
