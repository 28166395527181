import React from 'react'
import './cards.css'

const CardIndustry = () => {
  return (
    <div className="card-industry-container">
                <div className="col-content-inner">
                        <div className='col-content-subinner-1'>
                            <div className='col-content-subinner-2'>
                                <div className='col-content-subinner-3'>
                                    <div className='col-content-subinner-4'>
                                        <div className='col-content-subinner'>
                                            <div className='red-line-icon'/>
                                            <div className="container-sub-indust">
                                                <span className="indust-cont-top-text">Industries</span>
                                                <span className="indust-cont-bottm-text">We partner with airlines, hoteliers, agencies and other travel partners to retail, distribute and fulfill travel. Select your industry below to learn more.</span>
                                            </div>
                                        </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
  )
}

export default CardIndustry