import React from 'react'
import '../cards.css'

const Joinusnow = () => {
  return (
    <div className='card-joinus-container'>
      <div className='col-innov-content-subinner'/>
        {/* <div className='col-innov-img-subinner'/> */}
        <div className='col-innov-content-inner'>
            <div className='red-line-icon'/>
            <div className="container-sub-indust">
                <span className="indust-innov-top-text">Join Our Journey</span>
                <span className="indust-innov-bottm-text">Learn more about what Sabre has to offer you and your career – from the people to our inclusive environment to the benefits and wellness programs we’re proud to offer our team members.</span>
                <button id='joinusbutn'>Join Us Now</button>
            </div>
        </div>
    </div>
  )
}

export default Joinusnow