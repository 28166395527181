// Resources.js
import React, { useState, useEffect } from "react";
import "./resources.css";
import { BsChevronDoubleRight } from "react-icons/bs";
import axios from "axios";
import { API_FETCH } from "../../server";
import { useSelector, useDispatch } from "react-redux";

import { getResourcesdata } from "../../Redux/Actions/searchResponse";
import Sidebar from "./Sidebar";
import ResourceContent from "./ResourceContent";

const Resources = () => {
  const [swipe, setSwipe] = useState(true);
  const [pageNumber, setPageNumber] = useState(0);
  const dispatch = useDispatch();
  const { resourcesdata } = useSelector((state) => state.datalist);
  const url1 = "https://developer.sabre.com/";

  useEffect(() => {
    handleFetchResources();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleFetchResources = async () => {
    try {
      const response = await axios.get(`${API_FETCH}/resources`);
      if (response.data.success) {
        dispatch(getResourcesdata(response.data.data));
      }
    } catch (e) {
      console.error(e);
    }
  };

  const handleSwipe = () => {
    setSwipe(!swipe);
  };

  return (
    <div className="resources-container">
      <div className="blogpagee-container-bottm">
        <div
          className={
            swipe ? "exec-left-div-blogpage" : "exec-left-div-blogpage-swipe"
          }
        >
          <Sidebar
            handleSabrelab={() => {}}
            handleSabreDev={() => {
              window.open(url1, "_blank", "noopener,noreferrer");
            }}
            handleRedapp={() => {}}
          />
        </div>
        <ResourceContent
          data={resourcesdata.filter(
            (item) => item.resourceType.name !== "videos"
          )}
          itemsPerPage={8}
          pageNumber={pageNumber}
          setPageNumber={setPageNumber}
          resourceTitle="Sabre Resources"
        />
        <div className="chevron-right-exec-cont" onClick={handleSwipe}>
          <BsChevronDoubleRight id="chevron-right-exec" />
        </div>
      </div>
    </div>
  );
};

export default Resources;
