import React, { useState, useEffect } from "react";
import "./Babcock.css";
import axios from "axios";
import Formloader from "../../Components/Formloader/Formloader";
import Singleselect from "../../Components/dropdown/Singleselect/Singleselect";
import { Server_API } from "../../server";

const Babcockpage = () => {
  const Socialjs = [
    {
      ID: 1,
      value: "Harmattan Semester - January",
    },
    {
      ID: 1,
      value: "Rain Semester - July",
    },
  ];
  const [erroverlay, setErroverlay] = useState({
    status: false,
    message: "",
  });
  const [submitstatus, setSubmitstatus] = useState(0);
  const [loaddots, setLoaddots] = useState(false);

  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  const [register, setRegister] = useState({
    fname: "",
    phoneno: "",
    email: "",
    enrollmentperiod: "",
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    const regex = /^[0-9]+$/;

    if (name === "fname" || name === "email") {
      setRegister({ ...register, [name]: value });
    } else {
      if (event.target.value.length <= 14 && regex.test(event.target.value)) {
        setRegister({ ...register, [name]: value });
      }
    }
  };

  const handleformSubmit = async (event) => {
    event.preventDefault();
    setErroverlay({
      status: true,
      message: "",
    });
    setLoaddots(true);

    try {
      var config = {
        method: "POST",
        url: `${Server_API}/mba`,
        data: {
          name: register.fname,
          phone: register.phoneno,
          email: register.email,
          enrollmentperiod: register.enrollmentperiod,
        },
      };
      console.log(config);

      await axios(config).then((response) => {
        console.log(response);
        if (response.data.success === true) {
          setSubmitstatus(1);
          setLoaddots(false);
          setErroverlay({
            status: true,
            message: "Submission Successful!",
          });
          clearField();
        }
      });
    } catch (e) {
      setSubmitstatus(2);
      setLoaddots(false);
      clearField();
    }
  };

  const clearField = () => {
    setRegister({
      fname: "",
      phoneno: "",
      email: "",
    });
  };

  const handleClose = () => {
    setErroverlay({
      status: false,
      message: "",
    });
    setLoaddots(false);
  };

  const handleselectedSocVal = (incomingval) => {
    setRegister({ ...register, enrollmentperiod: incomingval });
  };

  return (
    <div className="babcock-container">
      <Formloader
        overlay={erroverlay}
        submissionstatus={submitstatus}
        closeoverlay={handleClose}
      />
      <div className="mid-babcock-container">
        <div className="babc-page-header">Programme Details</div>

        <div className="babc-topp">
          <div className="babc-top-left-container">
            <div className="babc-top-left" />
          </div>
          <div className="babc-top-right">
            BABCOCK UNIVERSITY, <br />
            ILISHAN-REMO, OGUN STATE, NIGERIA <br /> COLLEGE OF POSTGRADUATE
            STUDIES
          </div>
        </div>

        <div className="office-inform-contain">
          Babcock University in collaboration with Sabre Network Inc. and
          AlphaFirst Travel & Tourism Academy commence MBA programme in Airline
          & Travel Management
        </div>

        <div className="babc-container-text">
          Babcock University (Centre for Executive Development) in partnership
          with Sabre Network Inc. and AlphaFirst Travel & Tourism Academy (IATA
          Certified) commence Professional Masters programme (MBA) in Airline
          and Travels Management.
          <br />
          <br />
          This programme will provide students with solid theoretical and
          empirical knowledge on Business Administration, General Environment of
          Travel & Tourism, Aviation Safety & Security, Essential of Airline
          Training and Technology, Fundamental of Airline Operations, amongst
          others.
        </div>

        <div className="babc-container-butm">
          <span>Kindly fill your details below to proceed</span>

          <form onSubmit={handleformSubmit} className="form-div-container">
            <div className="form-container">
              <div className="form-div-contain">
                <span>
                  Your Name <p>*</p>
                </span>
                <input
                  name="fname"
                  placeholder="Your Full Name"
                  type="text"
                  value={register.fname}
                  className="input-field-form"
                  onChange={handleChange}
                  required
                />
              </div>

              <div className="form-div-contain">
                <span>
                  Your Phone <p>*</p>
                </span>
                <input
                  name="phoneno"
                  placeholder="Phone Number"
                  type="tel"
                  value={register.phoneno}
                  className="input-field-form"
                  onChange={handleChange}
                  required
                />
              </div>

              <div className="form-div-contain">
                <span>
                  Your Email <p>*</p>
                </span>
                <input
                  name="email"
                  placeholder="Email Address"
                  type="email"
                  value={register.email}
                  className="input-field-form"
                  onChange={handleChange}
                  required
                />
              </div>

              <div className="form-div-contain">
                <span>
                  Period of Enrollment <p>*</p>
                </span>
                <Singleselect
                  selectedSocVal={handleselectedSocVal}
                  itemList={Socialjs}
                  placeholder="Module of Interest"
                />
              </div>
            </div>
            <div className="babcock-submit-container">
              <input type="submit" id="submit-id" value="Submit Form" />
              {loaddots && <div className="dots-1"></div>}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Babcockpage;
