import React, {useState} from 'react'
import './Footer.css'
import { FaFacebookF, FaTwitter, FaInstagram, FaWhatsapp } from "react-icons/fa";
import { ImYoutube2  } from "react-icons/im";
import {Link} from 'react-router-dom'

const Footers = () => {
  const [emailvalue, setEmailvalue] = useState()
  const handleChange = (e) =>{
    setEmailvalue(e.target.value)
  }

  const subscribeEmail = (e) =>{
    e.preventDefault()
    setEmailvalue("")
  }

  return (
    <div className="mainfooter-area">
        <div className="row-mx-md-5">
          <div className="col-md-3">
              <div id='foot-subhead'>About Us</div>
              <p>We are an Innovative technology company that lead the aviation industry and helping our customers succeed.</p>
          </div>
          <div className="col-md-3">
              <div id='foot-subhead'>Our Company</div>
              <a href="https://">How to join us</a>
              <a href="https://">About Us</a>
              <a href="https://">Products and Services</a>
              <a href="https://">Testimonial</a>
              <a href="https://">Copyright Notice</a>
          </div>
          <div className="col-md-3">
              <div id='foot-subhead'>Help and FAQ</div>
              <Link href="https://">How to be an Agent</Link>
              <Link to="/join-us-babcock">Be A Partner</Link>
              <Link href="https://">Gallery</Link>
          </div>
          <div className="col-md-3 col-sm-12 text-md-left text-center">
            <div id='foot-subhead'>Subscribe</div>
            <div className="ll-footer-newsletter">
              <p>Get first-hand information about our products and services. Subscribe to our newsletter
              </p>

              <div className="ll-subscription">
                <form onSubmit={subscribeEmail}>
                  <div className="ll-flex">
                    <div style={{flex: 1}}>
                      <input type="email" name="email" value={emailvalue} onChange={handleChange} placeholder="john@example.com" required/>
                    </div>
                      <button id='butn-idn' type="submit">&#8594;</button>
                  </div>
                </form>
              </div>

              <div className="ll-socialmedia">
              <a className="social-facebook" href="https://www.facebook.com/SabreCentralWestAfrica"><FaFacebookF id='iconns'/></a>
                <a className="social-twitter" href="https://twitter.com/Sabre_Corp"><FaTwitter id='iconns'/></a>
                <a className="social-instagram" href="https://www.instagram.com/sabrecentralwestafrica/"><FaInstagram id='iconns'/></a>
                <a className="social-googleplus" href="https://www.youtube.com/channel/UClpyK6M40Qkt2WlNF2NKllA"><ImYoutube2 id='iconns'/></a>
                <a className="social-pinterest" href="https://wa.me/08183598937"><FaWhatsapp id='iconns'/></a>
              </div>
            </div>
          </div>
        </div>
    </div>
)
}

export default Footers