// Sidebar.js
import React from "react";
import { Link } from "react-router-dom";

const Sidebar = ({ handleSabrelab, handleSabreDev, handleRedapp }) => {
  return (
    <div className="exec-left-blog-category">
      <div className="exec-left-blog-subcategory" onClick={handleSabreDev}>
        <span>SABRE DEV STUDIO</span>
      </div>
      <Link to="/response/resource" className="exec-left-blog-subcategory">
        <span>SABRE MANUALS</span>
      </Link>
      <Link to="/response/videos" className="exec-left-blog-subcategory">
        <span>SABRE VIDEOS</span>
      </Link>
      <div className="exec-left-blog-subcategory" onClick={handleRedapp}>
        <span>RED APP CENTER</span>
      </div>
      <div className="exec-left-blog-subcategory" onClick={handleSabrelab}>
        <span>SABRE LABS & RESEARCH</span>
      </div>
    </div>
  );
};

export default Sidebar;
