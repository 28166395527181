import React, { useState, useEffect } from "react";
import "./ResourceCard.css";
import { TbArrowBigDownLinesFilled } from "react-icons/tb";
import axios from "axios";
import { API_FETCH } from "../../server";
import { Store } from "../../Redux/store";
import { getResourcesdata } from "../../Redux/Actions/searchResponse";

const ResourceCard = ({ title, imgurl, pdfUrl, fullobj }) => {
  const [book, setBook] = useState({
    id: 1,
    resource_type_id: null,
    title: "",
    short_des: "",
    long_des: "",
    file_url: "",
    img_url: "",
    no_downloads: null,
    website_display: null,
    created: "",
    modified: "",
  });

  useEffect(() => {
    if (fullobj) {
      setBook({ ...fullobj, no_downloads: fullobj.no_downloads + 1 });
    }
  }, [fullobj]);

  const handleDownload = async (id) => {
    try {
      const data = JSON.stringify({
        resource_type_id: book.resource_type_id,
        title: book.title,
        short_des: book.short_des,
        long_des: book.long_des,
        file_url: book.file_url,
        img_url: book.img_url,
        no_downloads: book.no_downloads,
        website_display: book.website_display,
      });

      if (pdfUrl) {
        const config = {
          method: "PUT",
          url: `${API_FETCH}/resources/${id}`,
          data: data,
          headers: {
            "Content-Type": "application/json",
          },
        };

        const response = await axios(config);
        if (response.data.success) {
          Store.dispatch(getResourcesdata(response.data.data));
          window.open(pdfUrl, "_blank");
        }
      } else {
        alert("PDF file not available for download");
      }
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <div className="resourcecard-container shadow-sm p-1">
      <div
        className="book-image"
        style={{ backgroundImage: `url(${imgurl})` }}
      />
      <span>{title}</span>
      <div
        className="relative w-full h-[50px]"
        onClick={() => handleDownload(fullobj.id)}
      >
        <TbArrowBigDownLinesFilled
          id="resource-download-icon"
          size={25}
          className="absolute bottom-3 left-8"
        />
        <button id="download-butn">Download</button>
      </div>
    </div>
  );
};

export default ResourceCard;
