import React, { useState, useEffect } from "react";
import "./viewMoreBlog.css";
import { formatDate } from "../../config/dateFormat";
import { useSelector } from "react-redux";
import SearchField from "../../Components/Search";
import InnovationSmCard from "../../Components/Cards/Innovation/Innovationsmallercard";

const ViewMoreBlog = () => {
  const [blogPosts, setBlogPosts] = useState([]);
  const [more, setMore] = useState(12);

  const { newbloglist } = useSelector((state) => state.newsblog);
  const { datalist } = useSelector((state) => state.datalist);

  useEffect(() => {
    setBlogPosts(newbloglist.filter((items) => items.category === "blog"));
  }, [newbloglist]);

  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  const handleBlogClick = () => {};

  const handleViewMore = () => {
    setMore((prevMore) => prevMore + 12);
  };

  return (
    <div className="container-body mb-9">
      <div className="w-full mt-[40px] mb-[30px] max-w-[800px] flex flex-col gap-3">
        <h1 className="text-[2em] font-bold">Insights Blog</h1>
        <p className="text-[15px]">
          Sabre Insights is a resource for travel professionals to discover and
          apply the trends that move today’s travel industry. Our team curates a
          collection of expert views, data, research, and industry outlooks from
          across travel and technology.
        </p>
      </div>

      <SearchField data={blogPosts} />

      <div>
        <div className="flex w-full h-fit px-6 flex-wrap gap-2 justify-center">
          {datalist
            ?.slice()
            ?.sort((a, b) => new Date(b.modified) - new Date(a.modified))
            ?.slice(0, more) // Show only the first "more" number of items
            ?.map((post, index) => (
              <InnovationSmCard
                key={post.id}
                slideImageurl={post.imageurl}
                dates={formatDate(post.modified)}
                title={post.title}
                subtitle={post.caption}
                butnlabel={post.buttonlabel}
                buttonclicked={() => handleBlogClick(post.id)}
                linkurl={post.title}
              />
            ))}
        </div>
        {blogPosts.length > more && (
          <button
            onClick={handleViewMore}
            className="text-[13px] text-[white] bg-[#E50000] rounded-md border-black hover:bg-[#920000] hover:text-[#d1b1b1]"
          >
            View More
          </button>
        )}
      </div>
    </div>
  );
};

export default ViewMoreBlog;
